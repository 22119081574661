import { instanceToInstance, plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { apiService } from '@shared/api';
import { PaymentIntent, PaymentIntentCreateRequest } from './models';

class PaymentService {
  public createPaymentIntent(stripeProductID: string): Observable<PaymentIntent> {
    const request = new PaymentIntentCreateRequest({ stripeProductID });

    return apiService
      .post<PaymentIntent>('/subscriptions', instanceToInstance(request))
      .pipe(map((response) => plainToInstance(PaymentIntent, response)));
  }
}

export const paymentService = new PaymentService();
