import { Expose } from 'class-transformer';
import { BaseEntity } from '@shared/base-entity';

export class Location extends BaseEntity<number> {
  @Expose()
  public lat: number;

  @Expose({ name: 'lon' })
  public lng: number;

  @Expose({ name: 'fake_lat' })
  public fakeLat: number;

  @Expose({ name: 'fake_lon' })
  public fakeLng: number;

  constructor(model: Partial<Location>) {
    super(model);
    Object.assign(this, model);
  }
}
