import { AxiosError } from 'axios';
import { Setting } from '@shared/setting';
import { User } from '@shared/user';
import { defineAction } from '@store/utils';

export class ProfileActions {
  public static refreshProfile = defineAction(
    '[Profile] Refresh Profile'
  );

  public static refreshProfileSuccess = defineAction<User>(
    '[Profile] Refresh Profile Success'
  );

  public static refreshProfileFailure = defineAction<AxiosError>(
    '[Profile] Refresh Profile Failure'
  );

  public static loadSettings = defineAction(
    '[Profile] Load Settings'
  );

  public static loadSettingsSuccess = defineAction<Array<Setting>>(
    '[Profile] Load Settings Success'
  );

  public static loadSettingsFailure = defineAction<AxiosError>(
    '[Profile] Load Settings Failure'
  );

  public static getLocation = defineAction(
    '[Profile] Get Location'
  );

  public static getLocationSuccess = defineAction<Partial<GeolocationCoordinates>>(
    '[Profile] Get Location Success'
  );

  public static getLocationFailure = defineAction(
    '[Profile] Get Location Failure'
  );

  public static patchProfileState = defineAction<Partial<User>>(
    '[Profile] Patch profile state'
  );

  public static clearProfile = defineAction(
    '[Profile] Clear profile'
  );
}
