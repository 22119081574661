import React, { ReactElement } from 'react';
import './tab-bar.scss';
import { useTranslation } from '@shared/i18n';
import { TabBarItem, TabBarItemProps } from '../tab-bar-item/tab-bar-item';

export function TabBar(): ReactElement {
  const translate = useTranslation('SHARED.TAB_BAR_LAYOUT');

  const itemsData: Array<TabBarItemProps> = [
    {
      iconVariant: 'search',
      text: translate('LINK_DISCOVER'),
      link: '/home'
    },
    {
      iconVariant: 'gift',
      text: translate('LINK_REWARDS'),
      link: '/rewards'
    },
    {
      iconVariant: 'timer',
      text: translate('LINK_LAST_MIN'),
      link: '/last-minute-escapes'
    },
    {
      iconVariant: 'profile',
      text: translate('LINK_PROFILE'),
      link: '/profile'
    }
  ];

  return (
    <div className='tab-bar'>
      {itemsData.map(({ link, ...restProps }) => (
        <TabBarItem
          key={link}
          link={link}
          {...restProps} />
      ))}
    </div>
  );
}
