import { Exclude, Expose, Type } from 'class-transformer';
import { BaseEntity } from '@shared/base-entity/models';
import { Collection } from '@shared/collection';
import { Location } from '@shared/location';
import { Media } from '@shared/media';
import { Owner } from '@shared/owner';

export class PropertiesGroup extends BaseEntity<number> {
  @Expose()
  public name: string;

  @Expose()
  @Type(() => Media)
  public photos: Array<Media>;

  @Expose()
  @Type(() => Location)
  public location: Location;

  @Expose()
  public highlights: string;

  @Expose()
  public address: string;

  @Expose()
  @Type(() => Number)
  public price: number;

  @Expose({ name: 'price_text' })
  public priceText: string;

  @Expose({ name: 'min_number_of_guests' })
  @Type(() => Number)
  public minNumberOfGuests: number;

  @Expose({ name: 'max_number_of_guests' })
  @Type(() => Number)
  public maxNumberOfGuests: number;

  @Expose({ name: 'min_number_of_children' })
  @Type(() => Number)
  public minNumberOfChildren: number;

  @Expose({ name: 'max_number_of_children' })
  @Type(() => Number)
  public maxNumberOfChildren: number;

  @Expose()
  @Type(() => Owner)
  public owner: Owner;

  @Expose()
  public link: string;

  @Expose({ name: 'is_premium' })
  public isPremium: boolean;

  @Expose()
  public notice: string;

  @Expose({ name: 'properties_count' })
  @Type(() => Number)
  public propertiesCount?: number;

  @Expose()
  @Type(() => Collection)
  public collections?: Array<Collection>;

  @Expose({ name: 'has_properties_without_collections' })
  public hasPropertiesWithoutCollections: boolean;

  @Exclude()
  public get thumbnails1024(): Array<Media> {
    return this.photos.map((photo) => photo.thumbnail1024);
  }

  @Exclude()
  public get thumbnails512(): Array<Media> {
    return this.photos.map((photo) => photo.thumbnail512);
  }

  @Exclude()
  public get squares1024(): Array<Media> {
    return this.photos.map((photo) => photo.square1024).filter((square1024) => !!square1024);
  }

  constructor(model: Partial<PropertiesGroup>) {
    super(model);

    Object.assign(this, model);
  }
}
