import { BaseListedEntitySelectors } from '@shared/base-listed-entity-store';
import { PropertyType } from '@shared/property-type';
import { AppState } from '@store/types';
import { PropertyTypesMultiselectState } from './state';

const selectFeature = (state: AppState): PropertyTypesMultiselectState => state.propertyTypesMultiselect;

class PropertyTypesMultiselectSelectors extends BaseListedEntitySelectors<PropertyType, PropertyTypesMultiselectState> {
  constructor() {
    super(
      selectFeature,
      'propertyType'
    );
  }
}

export const propertyTypesMultiselectSelectors = new PropertyTypesMultiselectSelectors();
