import { AxiosError } from 'axios';
import { Collection } from '@shared/collection/models';
import { PropertiesGroup } from '@shared/properties-group/models';
import { SearchPaginationRequest } from '@shared/search';
import { defineAction } from '@store/utils';

export class PropertiesGroupPageActions {
  public static resetState = defineAction(
    '[Properties Group Page] Reset State'
  );

  public static loadPropertiesGroup = defineAction<{ id: number }>(
    '[Properties Group Page] Load Properties Group'
  );

  public static loadPropertiesGroupSuccess = defineAction<PropertiesGroup>(
    '[Properties Group Page] Load Properties Group Success'
  );

  public static loadPropertiesGroupFailure = defineAction<AxiosError>(
    '[Properties Group Page] Load Properties Group Failure'
  );

  public static loadAllCollections = defineAction<{
    propertiesGroupID: number;
  }>(
    '[Properties Group Page] Load All Collections'
  );

  public static loadAllCollectionsSuccess = defineAction<Array<Collection>>(
    '[Properties Group Page] Load All Collections Success'
  );

  public static loadAllCollectionsFailure = defineAction<AxiosError>(
    '[Properties Group Page] Load All Collections Failure'
  );

  public static loadAppropriateCollections = defineAction<{
    propertiesGroupID: number;
    filters: SearchPaginationRequest;
  }>(
    '[Properties Group Page] Load Appropriate Collections'
  );

  public static loadAppropriateCollectionsSuccess = defineAction<Array<Collection>>(
    '[Properties Group Page] Load Appropriate Collections Success'
  );

  public static loadAppropriateCollectionsFailure = defineAction<AxiosError>(
    '[Properties Group Page] Load Appropriate Collections Failure'
  );
}
