import { mergeMap, of } from 'rxjs';
import { catchError, exhaustMap, filter, map } from 'rxjs/operators';
import { AuthActions, authService } from '@shared/auth';
import { Epics } from '@store/types';
import { SignupPageActions } from './actions';
import { SignupPageSelectors } from './selectors';

export const signupPageEpics: Epics = {
  checkCode: (action$) => action$.pipe(
    filter(SignupPageActions.checkCode.match),
    exhaustMap(({ payload: { code } }) => authService.checkCode(code).pipe(
      map(() => SignupPageActions.checkCodeSuccess({ code })),
      catchError((error) => of(SignupPageActions.checkCodeFailure(error)))
    ))
  ),

  register: (action$, state$) => action$.pipe(
    filter(SignupPageActions.register.match),
    exhaustMap(({ payload }) => {
      const code = SignupPageSelectors.code(state$.value);

      return authService
        .register({
          ...payload,
          code,
          remember: true
        })
        .pipe(
          mergeMap((response) => [
            SignupPageActions.registerSuccess(),
            AuthActions.authorizeSuccess({ response, navigateTo: '/complete-profile' })
          ]),
          catchError((error) => of(SignupPageActions.registerFailure(error)))
        );
    })
  )
};
