import { Expose, Type } from 'class-transformer';
import { BaseEntity } from '@shared/base-entity';
import { Property } from '@shared/property';

export class Collection extends BaseEntity<number> {
  @Expose()
  public name: string;

  @Expose({ name: 'grouped_properties' })
  @Type(() => Property)
  public groupedProperties: Array<Property>;

  constructor(model: Partial<Collection>) {
    super(model);
    Object.assign(this, model);
  }
}
