import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { discountService } from '@shared/discount';
import { Epics } from '@store/types';
import { RewardViewPageActions } from './actions';

export const rewardViewPageEpics: Epics = {
  loadDiscount: (action$) => action$.pipe(
    filter(RewardViewPageActions.loadDiscount.match),
    switchMap(({ payload: { id } }) => discountService
      .get(id, {
        relations: ['discount_photos.media.square_1024', 'discountable.photos.square_1024', 'categories.icon']
      })
      .pipe(
        map((response) => RewardViewPageActions.loadDiscountSuccess(response)),
        catchError((error) => of(RewardViewPageActions.loadDiscountFailure(error)))
      ))
  )
};
