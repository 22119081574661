import { createReducer } from '@reduxjs/toolkit';
import { ProfileFormActions } from './actions';
import { ProfileFormState } from './state';

const initialState: ProfileFormState = {
  isSubmitting: false
};

export const profileFormReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(ProfileFormActions.resetState, () => initialState)
    .addCase(ProfileFormActions.update, (state) => {
      state.isSubmitting = true;
    })
    .addCase(ProfileFormActions.updateSuccess, (state) => {
      state.isSubmitting = false;
    })
    .addCase(ProfileFormActions.updateFailure, (state) => {
      state.isSubmitting = false;
    });
});
