import { AxiosError } from 'axios';
import { BaseListedEntityActions } from '@shared/base-listed-entity-store';
import { BookedProperty } from '@shared/booked-property';
import { PaginationResponse } from '@shared/pagination';
import { Property, PropertyPaginationRequest } from '@shared/property';
import { defineAction } from '@store/utils';
import { AvailableDatesModalState } from './state';

export class AvailableDatesModalActions extends BaseListedEntityActions<AvailableDatesModalState, BookedProperty> {
  public setInitialPropertyID = defineAction<{ initialPropertyID: number }>(
    `[${this.storeTag}] Set initial property ID`
  );

  public loadProperties = defineAction(
    `[${this.storeTag}] Load properties`
  );

  public loadPropertiesSuccess = defineAction<PaginationResponse<Property>>(
    `[${this.storeTag}] Load properties success`
  );

  public loadPropertiesFailure = defineAction<AxiosError>(
    `[${this.storeTag}] Load properties failure`
  );

  public changePropertiesFilter = defineAction<PropertyPaginationRequest>(
    `[${this.storeTag}] Change properties filter`
  );

  constructor() {
    super(
      'Available Dates Modal',
      'bookedProperty'
    );
  }
}

export const availableDatesModalActions = new AvailableDatesModalActions();
