export enum ModalID {
  CONFIRMATION = 'confirmation',
  FILTERS = 'filters',
  SEARCH = 'search',
  DESKTOP_WARNING = 'desktop_warning',
  AVAILABLE_DATES = 'available_dates',
  SORTING = 'sorting',
  MAP = 'map',
  REWARDS_FILTERS = 'rewards_filters'
}
