import { EntityService } from '@shared/base-entity/service';
import { Discount, DiscountPaginationRequest } from './models';

class DiscountService extends EntityService<Discount, DiscountPaginationRequest> {
  constructor() {
    super({
      endpoint: '/discounts',
      entityName: 'discount',
      entitySearchRequestConstructor: DiscountPaginationRequest
    });
  }
}

export const discountService = new DiscountService();
