import { Expose } from 'class-transformer';
import { Setting } from './setting';

export class SettingLastMinuteEscapes extends Setting {
  @Expose({ name: 'link_whatsapp' })
  public whatsappLink: string;

  @Expose({ name: 'link_instagram' })
  public instagramLink: string;

  @Expose({ name: 'promocode_first' })
  public firstPromoCode: string;

  @Expose({ name: 'promocode_first_title' })
  public firstPromoCodeTitle: string;

  @Expose({ name: 'promocode_second' })
  public secondPromoCode: string;

  @Expose({ name: 'promocode_second_title' })
  public secondPromoCodeTitle: string;

  constructor(model: Partial<SettingLastMinuteEscapes>) {
    super(model);
    Object.assign(this, model);
  }
}
