import { baseListedEntityEpics } from '@shared/base-listed-entity-store';
import { Category, categoryService } from '@shared/category';
import { Epics } from '@store/types';
import { categoriesMultiselectActions } from './actions';
import { categoriesMultiselectSelectors } from './selectors';
import { CategoriesMultiselectState } from './state';

export const categoriesMultiselectEpics: Epics = baseListedEntityEpics<Category, CategoriesMultiselectState>(
  categoriesMultiselectActions,
  categoriesMultiselectSelectors,
  categoryService
);
