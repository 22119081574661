import { BaseListedEntitySelectors } from '@shared/base-listed-entity-store';
import { Category } from '@shared/category';
import { AppState } from '@store/types';
import { CategoriesMultiselectState } from './state';

const selectFeature = (state: AppState): CategoriesMultiselectState => state.categoriesMultiselect;

class CategoriesMultiselectSelectors extends BaseListedEntitySelectors<Category, CategoriesMultiselectState> {
  constructor() {
    super(
      selectFeature,
      'category'
    );
  }
}

export const categoriesMultiselectSelectors = new CategoriesMultiselectSelectors();
