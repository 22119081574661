import { Expose, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { BaseEntity } from '@shared/base-entity';
import { UtcTransformDate } from '@shared/class-transformer';

export class BookedProperty extends BaseEntity<number> {
  @Expose({ name: 'start_at' })
  @Type(() => DateTime)
  @UtcTransformDate()
  public startAt: DateTime;

  @Expose({ name: 'end_at' })
  @Type(() => DateTime)
  @UtcTransformDate()
  public endAt: DateTime;
}
