import { Expose, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { TransformBoolean, UtcTransformDate } from '@shared/class-transformer';
import { PaginationRequest } from '@shared/pagination';
import { PropertyRelation } from '../types';

export class PropertyPaginationRequest extends PaginationRequest<PropertyRelation> {
  @Expose({ name: 'with_multiple' })
  @TransformBoolean()
  public withMultiple?: boolean;

  @Expose({ name: 'top_right_lat' })
  public topRightLat?: number;

  @Expose({ name: 'top_right_lon' })
  public topRightLng?: number;

  @Expose({ name: 'bottom_left_lat' })
  public bottomLeftLat?: number;

  @Expose({ name: 'bottom_left_lon' })
  public bottomLeftLng?: number;

  @Expose({ name: 'price_from' })
  public priceFrom?: number;

  @Expose({ name: 'price_to' })
  public priceTo?: number;

  @Expose({ name: 'categories_ids' })
  public categoriesIDs?: Array<number>;

  @Expose({ name: 'number_of_guests_from' })
  public numberOfGuestsFrom?: number;

  @Expose({ name: 'property_group_id' })
  public propertiesGroupID?: number;

  @Expose({ name: 'start_at' })
  @Type(() => DateTime)
  @UtcTransformDate()
  public startAt?: DateTime;

  @Expose({ name: 'end_at' })
  @Type(() => DateTime)
  @UtcTransformDate()
  public endAt?: DateTime;

  @Expose({ name: 'has_calendar_link' })
  @TransformBoolean()
  public hasCalendarLink?: boolean;

  @Expose({ name: 'is_premium' })
  @TransformBoolean()
  public isPremium?: boolean;

  @Expose({ name: 'collection_ids' })
  public collectionsIDs?: Array<number>;

  @Expose({ name: 'without_collection' })
  @TransformBoolean()
  public withoutCollection?: boolean;

  constructor(model: Partial<PropertyPaginationRequest>) {
    super(model);

    Object.assign(this, model);
  }
}
