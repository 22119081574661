import { exhaustMap, filter, map, tap } from 'rxjs/operators';
import { authService } from '@shared/auth';
import i18n from '@shared/i18n/i18n';
import { toastService } from '@shared/toast';
import { AppActions } from '@store/actions';
import { Epics } from '@store/types';
import { PasswordRecoveryPageActions } from './actions';
import { PasswordRecoveryPageSelectors } from './selectors';

export const passwordRecoveryPageEpics: Epics = {
  requestPasswordRecovery: (action$, state$) => action$.pipe(
    filter(PasswordRecoveryPageActions.requestPasswordRecovery.match),
    exhaustMap(({ payload: { email } }) => authService.requestPasswordRecovery(email).pipe(
      map(() => {
        const isSuccessDisplayed = PasswordRecoveryPageSelectors.isSuccessDisplayed(state$.value);

        return isSuccessDisplayed
          ? PasswordRecoveryPageActions.resendRequestSuccess()
          : PasswordRecoveryPageActions.requestPasswordRecoverySuccess();
      })
    ))
  ),

  requestPasswordRecoverySuccess: (action$) => action$.pipe(
    filter(PasswordRecoveryPageActions.resendRequestSuccess.match),
    tap(() => toastService.show(i18n.t('ACCOUNT_ACCESS.PASSWORD_RECOVERY.TEXT_RESEND_SUCCESS'), 'success')),
    map(() => AppActions.noop())
  )
};
