import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { PremiumPageActions } from './actions';
import { PremiumPageState } from './state';

const initialState: PremiumPageState = {
  stripeProducts: [],
  isLoading: false,
  isSubmitting: false
};

export const premiumPageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(PremiumPageActions.resetState, () => initialState)
    .addCase(PremiumPageActions.loadStripeProducts, (state) => {
      state.isLoading = true;
    })
    .addCase(PremiumPageActions.loadStripeProductsSuccess, (state, { payload }) => {
      state.isLoading = false;
      state.stripeProducts = payload;
    })
    .addCase(PremiumPageActions.loadStripeProductsFailure, (state) => {
      state.isLoading = false;
    })
    .addCase(PremiumPageActions.createPaymentIntent, (state) => {
      state.isSubmitting = true;
    })
    .addMatcher(
      isAnyOf(PremiumPageActions.createPaymentIntentSuccess, PremiumPageActions.createPaymentIntentFailure),
      (state) => {
        state.isSubmitting = false;
      }
    );
});
