import { ClassConstructor, Exclude, Expose } from 'class-transformer';
import { Pagination } from './pagination';

export class PaginationResponse<TEntity extends object = object> extends Pagination {
  @Expose()
  public data: Array<TEntity>;

  @Exclude()
  private type?: ClassConstructor<TEntity>;

  constructor(type: ClassConstructor<TEntity>) {
    super();

    this.type = type;
  }
}
