import { Exclude, Expose, Type } from 'class-transformer';
import { compact } from 'lodash';
import { DateTime } from 'luxon';
import { BaseEntity } from '@shared/base-entity/models';
import { Category } from '@shared/category/models';
import { TransformBoolean, TransformDate } from '@shared/class-transformer';
import { Media } from '@shared/media';
import { Property } from '@shared/property/models';
import { DiscountPhoto } from './photo';

export class Discount extends BaseEntity<number> {
  @Expose()
  public title: string;

  @Expose()
  public subtitle: string;

  @Expose({ name: 'promo_code' })
  public promoCode: string;

  @Expose()
  @Type(() => DiscountPhoto)
  public photos: Array<DiscountPhoto>;

  @Expose()
  public website: string;

  @Expose({ name: 'is_premium' })
  @TransformBoolean()
  public isPremium: string;

  @Expose()
  @Type(() => Property)
  public discountable: Property;

  @Expose({ name: 'date_start' })
  @Type(() => DateTime)
  @TransformDate()
  public startDate: DateTime;

  @Expose({ name: 'date_end' })
  @Type(() => DateTime)
  @TransformDate()
  public endDate: DateTime;

  @Expose({ name: 'dates_text' })
  public datesText: string;

  @Expose({ name: 'saving_amount' })
  public savingAmount: string;

  @Expose()
  public terms: string;

  @Expose()
  public summary: string;

  @Expose({ name: 'normal_price_summary' })
  public normaPriceSummary: string;

  @Expose()
  public about: string;

  @Expose()
  @Type(() => Category)
  public categories: Array<Category>;

  @Expose({ name: 'normal_price' })
  public normalPrice: string;

  @Expose({ name: 'premium_price' })
  public premiumPrice: string;

  @Exclude()
  public get preview(): Media | undefined {
    return this.photos.length ? this.photos.find((photo) => photo.isPreview)?.media : this.discountable.photos[0];
  }

  @Exclude()
  public get previewSquare1024(): Media | undefined {
    return this.preview?.square1024;
  }

  @Exclude()
  public get orderedSquares1024(): Array<Media> {
    const mediaWithoutPreview = this.photos
      .flatMap((photo) => photo.media)
      .filter((media) => media.id !== this.preview?.id);
    const ownMedia = compact([this.preview, ...mediaWithoutPreview]).map((media) => media.square1024);

    return ownMedia.length ? ownMedia : this.discountable.squares1024;
  }

  constructor(model: Partial<Discount>) {
    super(model);

    Object.assign(this, model);
  }
}
