import { createReducer } from '@reduxjs/toolkit';
import { compact, uniq } from 'lodash';
import { baseEntityStoreReducer } from '@shared/base-listed-entity-store/store/reducer';
import { baseListedInitialState } from '@shared/base-listed-entity-store/store/state';
import { BookedProperty, BookedPropertyPaginationRequest } from '@shared/booked-property';
import { PropertyPaginationRequest } from '@shared/property';
import { AvailableDatesModalActions, availableDatesModalActions } from './actions';
import { AvailableDatesModalState } from './state';

const initialState: AvailableDatesModalState = {
  ...baseListedInitialState,
  filters: new BookedPropertyPaginationRequest({
    orderBy: 'start_at',
    desc: false,
    all: true
  }),
  initialPropertyID: null,
  propertiesIDs: [],
  propertiesFilters: new PropertyPaginationRequest({
    all: true,
    relations: ['photos.thumbnail_128'],
    hasCalendarLink: true
  })
};

export const availableDatesModalReducer = createReducer(initialState, (builder) => baseEntityStoreReducer<BookedProperty, AvailableDatesModalState, AvailableDatesModalActions>(
  initialState,
  availableDatesModalActions,
  builder
)
  .addCase(availableDatesModalActions.setInitialPropertyID, (state, { payload: { initialPropertyID } }) => {
    state.initialPropertyID = initialPropertyID;
  })
  .addCase(availableDatesModalActions.changePropertiesFilter, (state, { payload }) => {
    state.propertiesFilters = { ...state.propertiesFilters, ...payload };
  })
  .addCase(availableDatesModalActions.loadPropertiesSuccess, (state, { payload }) => {
    state.propertiesIDs = uniq(compact([state.initialPropertyID, ...payload.data.map((property) => property.id)]));
  }));
