import { defineAction } from './utils';

export class AppActions {
  public static init = defineAction(
    '[App] Init'
  );

  public static noop = defineAction(
    '[App] Noop'
  );
}
