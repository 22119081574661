import { Expose } from 'class-transformer';
import { BaseEntity } from '@shared/base-entity';

export class HistoryItem extends BaseEntity<number> {
  @Expose()
  public query: string;

  constructor(model: Partial<HistoryItem>) {
    super(model);
    Object.assign(this, model);
  }
}
