import { Exclude, Expose, Type } from 'class-transformer';
import { BaseEntity } from '@shared/base-entity';
import { Subscription } from '@shared/subscription';

export class User extends BaseEntity<number> {
  @Expose({ name: 'first_name' })
  public firstName: string;

  @Expose({ name: 'last_name' })
  public lastName: string;

  @Expose()
  public phone: string;

  @Expose()
  public email: string;

  @Expose()
  @Type(() => Subscription)
  public subscription: Subscription;

  @Expose({ name: 'has_subscription_access' })
  public hasSubscriptionAccess: boolean;

  @Exclude()
  public get fullName(): string {
    return [this.firstName, this.lastName].join(' ').trim();
  }

  @Exclude()
  public get arePremiumFeaturesAvailable(): boolean {
    return this.hasSubscriptionAccess || !!this.subscription?.isPaid;
  }

  constructor(model: Partial<User>) {
    super(model);
    Object.assign(this, model);
  }
}
