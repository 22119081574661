import { Expose, Type } from 'class-transformer';
import { BaseEntity } from '@shared/base-entity/models';

export class Media extends BaseEntity<number> {
  @Expose()
  public link: string;

  @Expose()
  public name: string;

  @Expose({ name: 'is_public' })
  public isPublic: boolean;

  @Expose({ name: 'owner_id' })
  public ownerID: number;

  @Expose({ name: 'thumbnail_128' })
  @Type(() => Media)
  public thumbnail128: Media;

  @Expose({ name: 'thumbnail_512' })
  @Type(() => Media)
  public thumbnail512: Media;

  @Expose({ name: 'thumbnail_1024' })
  @Type(() => Media)
  public thumbnail1024: Media;

  @Expose({ name: 'square_1024' })
  @Type(() => Media)
  public square1024: Media;

  constructor(model: Partial<Media>) {
    super(model);

    Object.assign(this, model);
  }
}
