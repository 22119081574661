import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { lastValueFrom, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const tokenRefreshInterceptor =
  (options: {
    onError: () => void;
    refreshToken: (remember?: boolean) => Observable<string | undefined>;
    getIsAuthenticated: () => boolean | null;
  }) => async (error: AxiosError<{ error?: string }>): Promise<unknown> => {
    const isAuthenticated = options.getIsAuthenticated();

    if (
      isAuthenticated &&
      error?.response?.data?.error &&
      ['Token not provided', 'Token has expired'].includes(error.response.data.error)
    ) {
      return lastValueFrom(
        options.refreshToken(true).pipe(
          map(() => axios.request(error.config as AxiosRequestConfig)),
          catchError(() => {
            options.onError();

            return throwError(() => error);
          })
        )
      );
    }

    return Promise.reject(error);
  };
