import { createReducer } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import { AuthActions } from './actions';
import { AuthState } from './state';

const initialState: AuthState = {
  isAuthenticated: false,
  isAuthorizing: false,
  errorStatus: null,
  initialPrivatePage: null
};

export const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(AuthActions.setIsAuthenticated, (state, { payload: { value } }) => {
      state.isAuthenticated = value;
      if (!value) {
        state.initialPrivatePage = null;
      }
    })
    .addCase(AuthActions.authorize, (state) => {
      state.isAuthorizing = true;
      state.errorStatus = null;
    })
    .addCase(AuthActions.authorizeSuccess, (state, { payload: { navigateTo } }) => {
      state.isAuthorizing = false;
      state.initialPrivatePage = navigateTo;
    })
    .addCase(AuthActions.authorizeFailure, (state, { payload }) => {
      state.isAuthorizing = false;
      state.errorStatus = payload.response?.status as StatusCodes;
    });
});
