import { createReducer } from '@reduxjs/toolkit';
import { baseEntityStoreReducer } from '@shared/base-listed-entity-store/store/reducer';
import { baseListedInitialState } from '@shared/base-listed-entity-store/store/state';
import { PropertyType, PropertyTypePaginationRequest } from '@shared/property-type';
import { PropertyTypesMultiselectActions, propertyTypesMultiselectActions } from './actions';
import { PropertyTypesMultiselectState } from './state';

const initialState: PropertyTypesMultiselectState = {
  ...baseListedInitialState,
  filters: new PropertyTypePaginationRequest({
    relations: ['icon'],
    all: true
  })
};

export const propertyTypesMultiselectReducer = createReducer(initialState, (builder) => baseEntityStoreReducer<PropertyType, PropertyTypesMultiselectState, PropertyTypesMultiselectActions>(
  initialState,
  propertyTypesMultiselectActions,
  builder
));
