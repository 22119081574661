import { createSelector } from '@reduxjs/toolkit';
import { SettingName, SettingValuesTypes } from '@shared/setting';
import { AppState } from '@store/types';
import { ProfileState } from './state';

const selectFeature = (state: AppState): ProfileState => state.profile;

export class ProfileSelectors {
  public static profile = createSelector(
    selectFeature,
    (state) => state.profile
  );

  public static isRefreshing = createSelector(
    selectFeature,
    (state) => state.isRefreshing
  );

  public static coords = createSelector(
    selectFeature,
    (state) => state.coords
  );

  public static areSettingsLoading = createSelector(
    selectFeature,
    (state) => state.areSettingsLoading
  );

  public static settings = createSelector(
    selectFeature,
    (state) => state.settings
  );

  public static settingValue = (name: SettingName) => createSelector(
    ProfileSelectors.settings,
    () => name,
    (settings, name) => settings.find((setting) => setting.name === name) as SettingValuesTypes[typeof name]
  );
}
