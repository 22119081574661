import Cookies from 'js-cookie';
import { configuration } from '@configuration';

export const defaultCookieOptions = { path: '/' };

type Key = keyof typeof configuration.cookies.keys;
type Data = Partial<Record<Key, string>>;

class CookieService {
  public set(data: Data, options: any = defaultCookieOptions): void {
    Object.entries(data).forEach(([key, value]) => {
      Cookies.set(configuration.cookies.keys[key as Key], value, options);
    });
  }

  public get(key: Key): string {
    return Cookies.get(configuration.cookies.keys[key as Key]) || '';
  }

  public destroy(keys: Array<Key>, options: any = defaultCookieOptions): void {
    for (const key of keys) {
      Cookies.remove(configuration.cookies.keys[key as Key], options);
    }
  }
}

export const cookieService = new CookieService();
