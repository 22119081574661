import { createSelector, Selector } from '@reduxjs/toolkit';
import { entityStoreSelectors } from '@shared/base-entity/store';
import { BaseListedEntitySelectors } from '@shared/base-listed-entity-store';
import { HistoryItem } from '@shared/history-item';
import { PropertiesGroup } from '@shared/properties-group';
import { Property } from '@shared/property';
import { AppState } from '@store/types';
import { SearchModalState } from './state';

const selectFeature = (state: AppState): SearchModalState => state.searchModal;

class SearchModalSelectors extends BaseListedEntitySelectors<Property, SearchModalState> {
  public propertiesGroupsFilters = createSelector(
    this.selectFeature,
    (state) => state.propertiesGroupsFilters
  );

  public propertiesGroupsIDs = createSelector(
    this.selectFeature,
    (state) => state.propertiesGroupsIDs
  );

  public propertiesGroups = entityStoreSelectors['propertiesGroup'].items(this.propertiesGroupsIDs) as any as Selector<
    AppState,
    Array<PropertiesGroup>
  >;

  public areHistoryItemsLoading = createSelector(
    this.selectFeature,
    (state) => state.areHistoryItemsLoading
  );

  public historyItemsIDs = createSelector(
    this.selectFeature,
    (state) => state.historyItemsIDs
  );

  public historyItems = entityStoreSelectors['historyItem'].items(this.historyItemsIDs) as any as Selector<
    AppState,
    Array<HistoryItem>
  >;

  constructor() {
    super(
      selectFeature,
      'property'
    );
  }
}

export const searchModalSelectors = new SearchModalSelectors();
