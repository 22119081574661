import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { PropertiesGroupPageActions } from './actions';
import { PropertiesGroupPageState } from './state';

const initialState: PropertiesGroupPageState = {
  propertiesGroupID: null,
  isLoading: false,
  allCollections: [],
  appropriateCollections: [],
  areCollectionsLoading: false
};

export const propertiesGroupPageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(PropertiesGroupPageActions.resetState, () => initialState)
    .addCase(PropertiesGroupPageActions.loadPropertiesGroup, (state) => {
      state.isLoading = true;
    })
    .addCase(PropertiesGroupPageActions.loadPropertiesGroupSuccess, (state, { payload: { id } }) => {
      state.isLoading = false;
      state.propertiesGroupID = id;
    })
    .addCase(PropertiesGroupPageActions.loadPropertiesGroupFailure, (state) => {
      state.isLoading = false;
    })
    .addCase(PropertiesGroupPageActions.loadAllCollectionsSuccess, (state, { payload }) => {
      state.areCollectionsLoading = false;
      state.allCollections = payload;
    })
    .addCase(PropertiesGroupPageActions.loadAppropriateCollectionsSuccess, (state, { payload }) => {
      state.areCollectionsLoading = false;
      state.appropriateCollections = payload;
    })
    .addMatcher(
      isAnyOf(PropertiesGroupPageActions.loadAllCollections, PropertiesGroupPageActions.loadAppropriateCollections),
      (state) => {
        state.areCollectionsLoading = true;
      }
    )
    .addMatcher(
      isAnyOf(
        PropertiesGroupPageActions.loadAllCollectionsFailure,
        PropertiesGroupPageActions.loadAppropriateCollectionsFailure
      ),
      (state) => {
        state.areCollectionsLoading = false;
      }
    );
});
