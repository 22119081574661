import { Expose, Type } from 'class-transformer';
import { BaseEntity } from '@shared/base-entity/models';
import { Media } from '@shared/media';

export class Category extends BaseEntity<number> {
  @Expose()
  public name: string;

  @Expose()
  @Type(() => Media)
  public icon: Media;

  constructor(model: Partial<Category>) {
    super(model);

    Object.assign(this, model);
  }
}
