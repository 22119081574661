import { useMediaQuery } from '@react-hooks-library/core';
import styles from './use-responsive-layout.module.scss';

export const useResponsiveLayout = (): typeof result => {
  const isMobile = useMediaQuery(`(max-width: ${styles.mobile})`);
  const isTablet = useMediaQuery(`(max-width: ${styles.tablet})`);

  const result = { isMobile, isTablet };

  return result;
};
