import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '@store/types';
import { PasswordRecoveryPageState } from './state';

const selectFeature = (state: AppState): PasswordRecoveryPageState => state.passwordRecoveryPage;

export class PasswordRecoveryPageSelectors {
  public static isSubmitting = createSelector(
    selectFeature,
    (state) => state.isSubmitting
  );

  public static isSuccessDisplayed = createSelector(
    selectFeature,
    (state) => state.isSuccessDisplayed
  );

  public static isCountdownStarted = createSelector(
    selectFeature,
    (state) => state.isCountdownStarted
  );
}
