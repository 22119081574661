import { AxiosError } from 'axios';
import { Coords } from 'google-map-react';
import { BaseListedEntityActions } from '@shared/base-listed-entity-store/store/actions';
import { HistoryItem } from '@shared/history-item';
import { PaginationResponse } from '@shared/pagination';
import { PropertiesGroup, PropertiesGroupPaginationRequest } from '@shared/properties-group';
import { Property } from '@shared/property';
import { defineAction } from '@store/utils';
import { SearchModalState } from './state';

export class SearchModalActions extends BaseListedEntityActions<SearchModalState, Property> {
  public getPlaceDetailsSuccess = defineAction<{ coords: Coords; types?: Array<string> }>(
    `[${this.storeTag}] Get Place Details Success`
  );

  public loadPropertiesGroups = defineAction<{ page?: number } & PropertiesGroupPaginationRequest>(
    `[${this.storeTag}] Load Properties Groups`
  );

  public loadPropertiesGroupsSuccess = defineAction<PaginationResponse<PropertiesGroup>>(
    `[${this.storeTag}] Load Properties Groups Success`
  );

  public loadPropertiesGroupsFailure = defineAction<AxiosError>(
    `[${this.storeTag}] Load Properties Groups Failure`
  );

  public changePropertiesGroupsFilters = defineAction<PropertiesGroupPaginationRequest>(
    `[${this.storeTag}] Change properties groups filter`
  );

  public loadHistoryItems = defineAction(
    `[${this.storeTag}] Load History Items`
  );

  public loadHistoryItemsSuccess = defineAction<PaginationResponse<HistoryItem>>(
    `[${this.storeTag}] Load History Items Success`
  );

  public loadHistoryItemsFailure = defineAction<AxiosError>(
    `[${this.storeTag}] Load History Items Failure`
  );

  public createHistoryItem = defineAction<{ query: string }>(
    `[${this.storeTag}] Create History Item`
  );

  public createHistoryItemSuccess = defineAction<HistoryItem>(
    `[${this.storeTag}] Create History Item Success`
  );

  public createHistoryItemFailure = defineAction(
    `[${this.storeTag}] Create History Item Failure`
  );

  public deleteHistoryItems = defineAction(
    `[${this.storeTag}] Delete History Items`
  );

  public deleteHistoryItemsSuccess = defineAction(
    `[${this.storeTag}] Delete History Items Success`
  );

  public deleteHistoryItemsFailure = defineAction(
    `[${this.storeTag}] Delete History Items Failure`
  );

  constructor() {
    super(
      'Search Modal',
      'property'
    );
  }
}

export const searchModalActions = new SearchModalActions();
