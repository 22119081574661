import { instanceToPlain, plainToInstance } from 'class-transformer';
import { isUndefined, omitBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { apiService } from '@shared/api';
import { User } from '@shared/user';
import { ChangePasswordRequest, ProfileGetRequest } from './models';

class ProfileService {
  public getProfile(params?: ProfileGetRequest): Observable<User> {
    const request = new ProfileGetRequest(omitBy(params, isUndefined));

    return apiService
      .get<User>('/profile', instanceToPlain(request))
      .pipe(map((response) => plainToInstance(User, response)));
  }

  public updateProfile(user: User): Observable<void> {
    return apiService.put<void>('/profile', instanceToPlain(user));
  }

  public changePassword(data: ChangePasswordRequest): Observable<void> {
    return apiService.put<void>('/profile', instanceToPlain(data));
  }

  public deleteProfile(): Observable<void> {
    return apiService.delete<void>('/profile');
  }
}

export const profileService = new ProfileService();
