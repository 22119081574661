import { of, switchMap } from 'rxjs';
import { catchError, exhaustMap, filter, map } from 'rxjs/operators';
import { authService } from '@shared/auth/service';
import { Epics } from '@store/types';
import { NewPasswordPageActions } from './actions';
import { NewPasswordPageSelectors } from './selectors';

export const newPasswordPageEpics: Epics = {
  checkToken: (action$) => action$.pipe(
    filter(NewPasswordPageActions.checkToken.match),
    switchMap(({ payload: { token } }) => authService.checkRestoreToken(token).pipe(
      map(() => NewPasswordPageActions.checkTokenSuccess({ token })),
      catchError((error) => of(NewPasswordPageActions.checkTokenFailure(error)))
    ))
  ),

  changePassword: (action$, state$) => action$.pipe(
    filter(NewPasswordPageActions.changePassword.match),
    exhaustMap(({ payload: { password, repeatedPassword } }) => {
      const token = NewPasswordPageSelectors.checkedToken(state$.value);

      return authService.restorePassword(token, password, repeatedPassword).pipe(
        map(() => NewPasswordPageActions.changePasswordSuccess()),
        catchError((error) => of(NewPasswordPageActions.changePasswordFailure(error)))
      );
    })
  )
};
