import { Expose } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';
import { DiscountOrderBy, DiscountRelation } from '../types';

export class DiscountPaginationRequest extends PaginationRequest<DiscountRelation, DiscountOrderBy> {
  @Expose({ name: 'latitude' })
  public browserLatitude?: number;

  @Expose({ name: 'longitude' })
  public browserLongitude?: number;

  @Expose({ name: 'available_months' })
  public availableMonths?: Array<number>;

  constructor(model: Partial<DiscountPaginationRequest>) {
    super(model);
    Object.assign(this, model);
  }
}
