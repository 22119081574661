import { baseListedEntityEpics } from '@shared/base-listed-entity-store';
import { PropertyType, propertyTypeService } from '@shared/property-type';
import { Epics } from '@store/types';
import { propertyTypesMultiselectActions } from './actions';
import { propertyTypesMultiselectSelectors } from './selectors';
import { PropertyTypesMultiselectState } from './state';

export const propertyTypesMultiselectEpics: Epics = baseListedEntityEpics<PropertyType, PropertyTypesMultiselectState>(
  propertyTypesMultiselectActions,
  propertyTypesMultiselectSelectors,
  propertyTypeService
);
