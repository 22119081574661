import { AxiosError } from 'axios';
import { defineAction } from '@store/utils';
import { AuthCredentials, AuthResponse } from '../models';

export class AuthActions {
  public static authorize = defineAction<AuthCredentials>(
    '[Auth] Authorize'
  );

  public static authorizeSuccess = defineAction<{ response: AuthResponse; navigateTo: string }>(
    '[Auth] Authorize Success'
  );

  public static authorizeFailure = defineAction<AxiosError>(
    '[Auth] Authorize Failure'
  );

  public static setIsAuthenticated = defineAction<{ value: boolean }>(
    '[Auth] Set Is Authenticated'
  );

  public static unauthorize = defineAction(
    '[Auth] Unauthorize'
  );

  public static unauthorizeSuccess = defineAction(
    '[Auth] Unauthorize Success'
  );
}
