import { EntityService } from '@shared/base-entity/service';
import { PropertiesGroup, PropertiesGroupPaginationRequest, PropertiesGroupRequest } from './models';

class PropertiesGroupService extends EntityService<
  PropertiesGroup,
  PropertiesGroupPaginationRequest,
  PropertiesGroupRequest
> {
  constructor() {
    super({
      endpoint: '/property-groups',
      entityName: 'propertiesGroup',
      entitySearchRequestConstructor: PropertiesGroupPaginationRequest,
      entityGetRequestConstructor: PropertiesGroupRequest
    });
  }
}

export const propertiesGroupService = new PropertiesGroupService();
