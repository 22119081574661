import React, { ReactElement } from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import { Icon, IconVariant } from '@shared/icon';
import './tab-bar-item.scss';

export interface TabBarItemProps {
  iconVariant: IconVariant;
  text: string;
  link: string;
}

export function TabBarItem({ iconVariant, text, link }: TabBarItemProps): ReactElement {
  const match = useMatch(link);
  const isActive = match?.pathnameBase === link;

  return (
    <NavLink to={link} className='tab-bar-item'>
      <Icon
        variant={iconVariant}
        mask={isActive ? 'blue' : 'gray'}
        className='tab-bar-item-icon' />
      <p>{text}</p>
    </NavLink>
  );
}
