import { Expose } from 'class-transformer';

export class ChangePasswordRequest {
  @Expose({ name: 'old_password' })
  public oldPassword: string;

  @Expose()
  public password: string;

  @Expose({ name: 'password_confirmation' })
  public passwordConfirmation: string;

  constructor(model: Partial<ChangePasswordRequest>) {
    Object.assign(this, model);
  }
}
