import { defineAction } from '@store/utils';

export class EditProfilePageActions {
  public static deleteAccount = defineAction(
    '[Edit Profile] Delete Account'
  );

  public static deleteAccountSuccess = defineAction(
    '[Edit Profile] Delete Account Success'
  );

  public static deleteAccountFailure = defineAction(
    '[Edit Profile] Delete Account Failure'
  );
}
