import { AxiosError } from 'axios';
import { Discount } from '@shared/discount';
import { defineAction } from '@store/utils';

export class RewardViewPageActions {
  public static resetState = defineAction(
    '[Reward View Page] Reset State'
  );

  public static loadDiscount = defineAction<{ id: number }>(
    '[Reward View Page] Load Discount'
  );

  public static loadDiscountSuccess = defineAction<Discount>(
    '[Reward View Page] Load Discount Success'
  );

  public static loadDiscountFailure = defineAction<AxiosError>(
    '[Reward View Page] Load Discount Failure'
  );
}
