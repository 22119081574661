import { createSelector } from '@reduxjs/toolkit';
import { cookieService } from '@shared/cookie';
import { AppState } from '@store/types';
import { AuthState } from './state';

const selectFeature = (state: AppState): AuthState => state.auth;

export class AuthSelectors {
  public static isAuthenticated = createSelector(
    selectFeature,
    (state) => state.isAuthenticated || cookieService.get('isAuthenticated') === 'true'
  );

  public static isAuthorizing = createSelector(
    selectFeature,
    (state) => state.isAuthorizing
  );

  public static errorStatus = createSelector(
    selectFeature,
    (state) => state.errorStatus
  );

  public static initialPrivatePage = createSelector(
    selectFeature,
    (state) => state.initialPrivatePage
  );
}
