import { defineAction } from '@store/utils';

export class PasswordRecoveryPageActions {
  public static resetState = defineAction(
    '[Password Recovery Page] Reset State'
  );

  public static requestPasswordRecovery = defineAction<{ email: string }>(
    '[Password Recovery Page] Request Password Recovery'
  );

  public static requestPasswordRecoverySuccess = defineAction(
    '[Password Recovery Page] Request Password Recovery Success'
  );

  public static resendRequestSuccess = defineAction(
    '[Password Recovery Page] Resend Request Success'
  );

  public static stopCountdown = defineAction(
    '[Password Recovery Page] Stop Countdown'
  );
}
