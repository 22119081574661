import { mergeMap, of } from 'rxjs';
import { catchError, exhaustMap, filter, tap } from 'rxjs/operators';
import { profileService } from '@shared/profile';
import { ProfileActions } from '@shared/profile/store/actions';
import { Epics } from '@store/types';
import { ProfileFormActions } from './actions';

export const profileFormEpics: Epics = {
  checkCode: (action$) => action$.pipe(
    filter(ProfileFormActions.update.match),
    exhaustMap(({ payload: { user, onSuccess } }) => profileService.updateProfile(user).pipe(
      tap(() => onSuccess()),
      mergeMap(() => [ProfileFormActions.updateSuccess(), ProfileActions.patchProfileState(user)]),
      catchError((error) => of(ProfileFormActions.updateFailure(error)))
    ))
  )
};
