import { createReducer } from '@reduxjs/toolkit';
import { baseEntityStoreReducer, baseListedInitialState } from '@shared/base-listed-entity-store';
import { PropertiesGroupPaginationRequest } from '@shared/properties-group';
import { Property, PropertyPaginationRequest } from '@shared/property';
import { SearchModalActions, searchModalActions } from './actions';
import { SearchModalState } from './state';

const initialState: SearchModalState = {
  ...baseListedInitialState,
  filters: new PropertyPaginationRequest({
    relations: ['photos.thumbnail_128', 'location', 'property_group'],
    withMultiple: true
  }),
  propertiesGroupsFilters: new PropertiesGroupPaginationRequest({
    relations: ['photos.thumbnail_128', 'location']
  }),
  propertiesGroupsIDs: [],
  historyItemsIDs: [],
  areHistoryItemsLoading: false
};

export const searchModalReducer = createReducer(initialState, (builder) => baseEntityStoreReducer<Property, SearchModalState, SearchModalActions>(initialState, searchModalActions, builder)
  .addCase(searchModalActions.loadPropertiesGroupsSuccess, (state, { payload: { data } }) => {
    state.propertiesGroupsIDs = data.map((item) => item.id);
  })
  .addCase(searchModalActions.loadHistoryItems, (state) => {
    state.areHistoryItemsLoading = true;
  })
  .addCase(searchModalActions.loadHistoryItemsSuccess, (state, { payload: { data } }) => {
    state.areHistoryItemsLoading = false;
    state.historyItemsIDs = data.map((item) => item.id);
  })
  .addCase(searchModalActions.loadHistoryItemsFailure, (state) => {
    state.areHistoryItemsLoading = false;
  })
  .addCase(searchModalActions.createHistoryItemSuccess, (state, { payload: { id } }) => {
    state.historyItemsIDs = [id, ...state.historyItemsIDs.slice(0, 4)];
  })
  .addCase(searchModalActions.deleteHistoryItemsSuccess, (state) => {
    state.historyItemsIDs = [];
  })
  .addCase(searchModalActions.changePropertiesGroupsFilters, (state, { payload }) => {
    state.propertiesGroupsFilters = { ...state.propertiesGroupsFilters, ...payload };
  }));
