import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { baseListedEntityEpics } from '@shared/base-listed-entity-store';
import { BookedProperty, bookedPropertyService } from '@shared/booked-property';
import { propertyService } from '@shared/property';
import { Epics } from '@store/types';
import { availableDatesModalActions } from './actions';
import { availableDatesModalSelectors } from './selectors';
import { AvailableDatesModalState } from './state';

export const availableDatesModalEpics: Epics = {
  ...baseListedEntityEpics<BookedProperty, AvailableDatesModalState>(
    availableDatesModalActions,
    availableDatesModalSelectors,
    bookedPropertyService
  ),

  loadProperties: (action$, state$) => action$.pipe(
    filter(availableDatesModalActions.loadProperties.match),
    switchMap(() => propertyService.search(availableDatesModalSelectors.propertiesFilters(state$.value)).pipe(
      map((response) => availableDatesModalActions.loadPropertiesSuccess(response)),
      catchError((error) => of(availableDatesModalActions.loadPropertiesFailure(error)))
    ))
  ),

  changePropertiesFilter: (action$) => action$.pipe(
    filter(availableDatesModalActions.changePropertiesFilter.match),
    map(() => availableDatesModalActions.loadProperties())
  )
};
