import React, { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface AuthRouteProps {
  isAuthenticated: boolean;
  isPrivate?: boolean;
}

export function AuthRoute({ isAuthenticated, isPrivate }: AuthRouteProps): JSX.Element {
  if (isPrivate && !isAuthenticated) {
    return <Navigate to='/' replace />;
  }

  if (!isPrivate && isAuthenticated) {
    return <Navigate to='/home' replace />;
  }

  return (
    <Suspense>
      <Outlet />
    </Suspense>
  );
}
