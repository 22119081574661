import { exhaustMap, mergeMap, of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { baseListedEntityEpics } from '@shared/base-listed-entity-store';
import { HistoryItem, historyItemService } from '@shared/history-item';
import { propertiesGroupService } from '@shared/properties-group';
import { Property, propertyService } from '@shared/property';
import { Epics } from '@store/types';
import { searchModalActions } from './actions';
import { searchModalSelectors } from './selectors';
import { SearchModalState } from './state';

export const searchModalEpics: Epics = {
  ...baseListedEntityEpics<Property, SearchModalState>(searchModalActions, searchModalSelectors, propertyService),

  loadPropertiesGroups: (action$, state$) => action$.pipe(
    filter(searchModalActions.loadPropertiesGroups.match),
    switchMap((action) => propertiesGroupService
      .search({
        ...(searchModalSelectors.propertiesGroupsFilters(state$.value) || {}),
        ...('payload' in action ? action.payload : {})
      })
      .pipe(
        map((response) => searchModalActions.loadPropertiesGroupsSuccess(response)),
        catchError((error) => of(searchModalActions.loadPropertiesGroupsFailure(error)))
      ))
  ),

  changeSearchQuery: (action$) => action$.pipe(
    filter(searchModalActions.changeSearchQuery.match),
    debounceTime(400),
    mergeMap(({ payload }) => [
      searchModalActions.changeFilter(payload),
      searchModalActions.changePropertiesGroupsFilters(payload)
    ])
  ),

  changePropertiesGroupsFilters: (action$) => action$.pipe(
    filter(searchModalActions.changePropertiesGroupsFilters.match),
    map(() => searchModalActions.loadPropertiesGroups({ page: 1 }))
  ),

  loadHistoryItems: (action$) => action$.pipe(
    filter(searchModalActions.loadHistoryItems.match),
    switchMap(() => historyItemService
      .search({
        all: true,
        orderBy: 'updated_at',
        desc: true
      })
      .pipe(
        map((response) => searchModalActions.loadHistoryItemsSuccess(response)),
        catchError((error) => of(searchModalActions.loadHistoryItemsFailure(error)))
      ))
  ),

  createHistoryItem: (action$) => action$.pipe(
    filter(searchModalActions.createHistoryItem.match),
    exhaustMap(({ payload }) => historyItemService.create(new HistoryItem(payload)).pipe(
      map((response) => searchModalActions.createHistoryItemSuccess(response)),
      catchError(() => of(searchModalActions.createHistoryItemFailure()))
    ))
  ),

  deleteHistoryItems: (action$) => action$.pipe(
    filter(searchModalActions.deleteHistoryItems.match),
    exhaustMap(() => historyItemService.deleteAll().pipe(
      map(() => searchModalActions.deleteHistoryItemsSuccess()),
      catchError(() => of(searchModalActions.deleteHistoryItemsFailure()))
    ))
  )
};
