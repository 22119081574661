import clsx from 'clsx';
import React, { AnchorHTMLAttributes, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { LocationState } from '@shared/link';
import './link.scss';

interface AppLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  onClick?: () => void;
  isBold?: boolean;
  isLoading?: boolean;
  isDangerous?: boolean;
  state?: LocationState;
}

export function AppLink({
  href,
  onClick,
  isBold,
  className,
  isLoading,
  isDangerous,
  state,
  ...restProps
}: AppLinkProps): ReactElement {
  function keyPressed(code: string): void {
    if (code === 'Enter') {
      onClick?.();
    }
  }

  const commonProps: AppLinkProps = {
    onClick: () => onClick?.(),
    onKeyDown: (event) => keyPressed(event.code),
    className: clsx('link', isBold && 'link-bold', isDangerous && 'link-dangerous', isLoading && 'loading', className),
    role: 'button'
  };

  return href ? (
    <Link
      to={href}
      state={state}
      {...commonProps}
      {...restProps} />
  ) : (
    <a {...commonProps} {...restProps} />
  );
}
