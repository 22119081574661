import { Exclude, Expose } from 'class-transformer';
import { SubscriptionStatus } from '../enums';

export class Subscription {
  @Expose()
  public id: number;

  @Expose()
  public status: SubscriptionStatus;

  @Exclude()
  public get isPaid(): boolean {
    return this.status === SubscriptionStatus.PAID;
  }

  constructor(model: Partial<Subscription> = {}) {
    Object.assign(this, model);
  }
}
