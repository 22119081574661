import { createSelector } from '@reduxjs/toolkit';
import { getFilterCount } from '@app/main/shared/get-filters-count';
import { AppState } from '@store/types';
import { HomePageState } from './state';

const selectFeature = (state: AppState): HomePageState => state.homePage;

export class HomePageSelectors {
  public static isLoading = createSelector(
    selectFeature,
    (state) => state.isLoading
  );

  public static items = createSelector(
    selectFeature,
    (state) => state.items
  );

  public static pagination = createSelector(
    selectFeature,
    (state) => state.pagination
  );

  public static filters = createSelector(
    selectFeature,
    (state) => state.filters
  );

  public static hasNextPage = createSelector(
    selectFeature,
    (state) => state.pagination.currentPage !== state.pagination.lastPage
  );

  public static hasNoItems = createSelector(
    selectFeature,
    (state) => !state.isLoading && state.pagination.total === 0
  );

  public static center = createSelector(
    selectFeature,
    (state) => state.center
  );

  public static zoom = createSelector(
    selectFeature,
    (state) => state.zoom
  );

  public static placeCoords = createSelector(
    selectFeature,
    (state) => state.placeCoords
  );

  public static view = createSelector(
    selectFeature,
    (state) => state.view
  );

  public static selectedFiltersCount = createSelector(
    HomePageSelectors.filters,
    (filters) => getFilterCount(filters)
  );
}
