import { Expose, Type } from 'class-transformer';
import { TransformBoolean } from '@shared/class-transformer';
import { Media } from '@shared/media';

export class DiscountPhoto {
  @Expose()
  @Type(() => Media)
  public media: Media;

  @Expose({ name: 'is_preview' })
  @TransformBoolean()
  public isPreview: boolean;

  constructor(model: Partial<DiscountPhoto>) {
    Object.assign(this, model);
  }
}
