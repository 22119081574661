import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { propertyService } from '@shared/property';
import { Epics } from '@store/types';
import { PropertyPageActions } from './actions';

export const propertyPageEpics: Epics = {
  loadProperty: (action$) => action$.pipe(
    filter(PropertyPageActions.loadProperty.match),
    switchMap(({ payload: { id } }) => propertyService
      .get(id, {
        relations: ['photos', 'categories.icon', 'property_group', 'location', 'property_type.icon']
      })
      .pipe(
        map((response) => PropertyPageActions.loadPropertySuccess(response)),
        catchError((error) => of(PropertyPageActions.loadPropertiesGroupFailure(error)))
      ))
  )
};
