import { EntityService } from '@shared/base-entity/service';
import { HistoryItem } from './models';

class HistoryItemService extends EntityService<HistoryItem> {
  constructor() {
    super({
      endpoint: '/search-histories',
      entityName: 'historyItem'
    });
  }
}

export const historyItemService = new HistoryItemService();
