import { Expose } from 'class-transformer';
import { EntityRequest } from '@shared/base-entity';
import { TransformBoolean } from '@shared/class-transformer';
import { PropertiesGroupRelation, PropertiesGroupWithCount } from '@shared/properties-group/types';

export class PropertiesGroupRequest extends EntityRequest<PropertiesGroupRelation, PropertiesGroupWithCount> {
  @Expose({ name: 'with_price' })
  @TransformBoolean()
  public withPrice?: boolean;

  constructor(model: Partial<PropertiesGroupRequest>) {
    super(model);

    Object.assign(this, model);
  }
}
