import { Expose } from 'class-transformer';

export class PaymentIntent {
  @Expose()
  public id: number;

  @Expose({ name: 'form_url' })
  public formURL: string;

  constructor(model: Partial<PaymentIntent> = {}) {
    Object.assign(this, model);
  }
}
