import { Expose } from 'class-transformer';
import { Setting } from './setting';

export class SettingPremiumRewardTexts extends Setting {
  @Expose()
  public button: string;

  @Expose()
  public title: string;

  constructor(model: Partial<SettingPremiumRewardTexts>) {
    super(model);
    Object.assign(this, model);
  }
}
