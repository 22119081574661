import { createReducer } from '@reduxjs/toolkit';
import { NewPasswordPageActions } from './actions';
import { NewPasswordPageState } from './state';

const initialState: NewPasswordPageState = {
  isChecking: false,
  isSubmitting: false,
  checkedToken: '',
  isCompleted: false
};

export const newPasswordPageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(NewPasswordPageActions.resetState, () => initialState)
    .addCase(NewPasswordPageActions.checkToken, (state) => {
      state.isChecking = true;
    })
    .addCase(NewPasswordPageActions.checkTokenSuccess, (state, { payload: { token } }) => {
      state.isChecking = false;
      state.checkedToken = token;
    })
    .addCase(NewPasswordPageActions.checkTokenFailure, (state) => {
      state.isChecking = false;
    })
    .addCase(NewPasswordPageActions.changePassword, (state) => {
      state.isSubmitting = true;
      state.isCompleted = false;
    })
    .addCase(NewPasswordPageActions.changePasswordSuccess, (state) => {
      state.isSubmitting = false;
      state.isCompleted = true;
    })
    .addCase(NewPasswordPageActions.changePasswordFailure, (state) => {
      state.isSubmitting = false;
    });
});
