import { ReactNode } from 'react';
import { toast, TypeOptions } from 'react-toastify';
import { ToastIconContainer } from './components';

class ToastService {
  public show(node: ReactNode, type?: TypeOptions): void {
    toast(node, {
      type,
      icon: () => (type ? ToastIconContainer({ type }) : undefined)
    });
  }
}

export const toastService = new ToastService();
