import { createSelector, Selector } from '@reduxjs/toolkit';
import { entityStoreSelectors } from '@shared/base-entity/store';
import { BaseListedEntitySelectors } from '@shared/base-listed-entity-store';
import { BookedProperty } from '@shared/booked-property';
import { Property } from '@shared/property';
import { AppState } from '@store/types';
import { AvailableDatesModalState } from './state';

const selectFeature = (state: AppState): AvailableDatesModalState => state.availableDatesModal;

class AvailableDatesModalSelectors extends BaseListedEntitySelectors<BookedProperty, AvailableDatesModalState> {
  public propertiesIDs = createSelector(
    this.selectFeature,
    (state) => state.propertiesIDs
  );

  public properties = entityStoreSelectors.property.items(this.propertiesIDs) as any as Selector<
    AppState,
    Array<Property>
  >;

  public propertiesFilters = createSelector(
    this.selectFeature,
    (state) => state.propertiesFilters
  );

  constructor() {
    super(
      selectFeature,
      'bookedProperty'
    );
  }
}

export const availableDatesModalSelectors = new AvailableDatesModalSelectors();
