import './icon.scss';
import clsx from 'clsx';
import React from 'react';
import { IconMask, IconVariant } from './types';

interface IconProps {
  variant: IconVariant;
  mask?: IconMask;
  className?: string;
}

export function Icon({ variant, mask, className }: IconProps): JSX.Element {
  return (
    <span className={clsx('icon', `icon-${variant}`, mask && 'icon-mask', mask && `icon-mask-${mask}`, className)} />
  );
}
