import { createReducer } from '@reduxjs/toolkit';
import { baseEntityStoreReducer } from '@shared/base-listed-entity-store/store/reducer';
import { baseListedInitialState } from '@shared/base-listed-entity-store/store/state';
import { Discount, DiscountPaginationRequest } from '@shared/discount';
import { RewardsPageActions, rewardsPageActions } from './actions';
import { RewardsPageState } from './state';

const initialState: RewardsPageState = {
  ...baseListedInitialState,
  filters: new DiscountPaginationRequest({
    relations: ['discount_photos.media.square_1024', 'discountable.photos.square_1024']
  })
};

export const rewardsPageReducer = createReducer(initialState, (builder) => baseEntityStoreReducer<Discount, RewardsPageState, RewardsPageActions>(initialState, rewardsPageActions, builder));
