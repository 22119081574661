import { Expose, Type } from 'class-transformer';
import { User } from '@shared/user';

export class AuthResponse {
  @Type(() => User)
  @Expose()
  public user: User;

  @Expose()
  public token: string;

  @Expose()
  public ttl: number;

  @Expose({ name: 'refresh_ttl' })
  public refreshTTL: number;
}
