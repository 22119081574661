import { createReducer } from '@reduxjs/toolkit';
import { PropertyPageActions } from './actions';
import { PropertyPageState } from './state';

const initialState: PropertyPageState = {
  propertyID: null,
  isLoading: false
};

export const propertyPageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(PropertyPageActions.resetState, () => initialState)
    .addCase(PropertyPageActions.loadProperty, (state) => {
      state.isLoading = true;
    })
    .addCase(PropertyPageActions.loadPropertySuccess, (state, { payload: { id } }) => {
      state.isLoading = false;
      state.propertyID = id;
    })
    .addCase(PropertyPageActions.loadPropertiesGroupFailure, (state) => {
      state.isLoading = false;
    });
});
