import { EntityService } from '@shared/base-entity/service';
import { Category } from './models';

class CategoryService extends EntityService<Category> {
  constructor() {
    super({
      endpoint: '/categories',
      entityName: 'category'
    });
  }
}

export const categoryService = new CategoryService();
