import { createReducer } from '@reduxjs/toolkit';
import { baseEntityStoreReducer } from '@shared/base-listed-entity-store/store/reducer';
import { baseListedInitialState } from '@shared/base-listed-entity-store/store/state';
import { Category, CategoryPaginationRequest } from '@shared/category';
import { CategoriesMultiselectActions, categoriesMultiselectActions } from './actions';
import { CategoriesMultiselectState } from './state';

const initialState: CategoriesMultiselectState = {
  ...baseListedInitialState,
  filters: new CategoryPaginationRequest({
    relations: ['icon'],
    all: true
  })
};

export const categoriesMultiselectReducer = createReducer(initialState, (builder) => baseEntityStoreReducer<Category, CategoriesMultiselectState, CategoriesMultiselectActions>(
  initialState,
  categoriesMultiselectActions,
  builder
));
