import { hide } from 'redux-modal';
import { mergeMap, of } from 'rxjs';
import { catchError, exhaustMap, filter } from 'rxjs/operators';
import { AuthActions } from '@shared/auth';
import { ModalID } from '@shared/modal/enums';
import { profileService } from '@shared/profile';
import { Epics } from '@store/types';
import { EditProfilePageActions } from './actions';

export const editProfilePageEpics: Epics = {
  checkCode: (action$) => action$.pipe(
    filter(EditProfilePageActions.deleteAccount.match),
    exhaustMap(() => profileService.deleteProfile().pipe(
      mergeMap(() => [
        EditProfilePageActions.deleteAccountSuccess(),
        hide(ModalID.CONFIRMATION),
        AuthActions.unauthorizeSuccess()
      ]),
      catchError(() => of(EditProfilePageActions.deleteAccountFailure()))
    ))
  )
};
