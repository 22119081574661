import { ClassConstructor, instanceToPlain, plainToInstance } from 'class-transformer';
import { isUndefined, omitBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { apiService } from '@shared/api';
import { PaginationRequest, PaginationResponse } from '@shared/pagination';
import { SettingName } from './enums';
import { Setting, SettingLastMinuteEscapes, SettingPremiumRewardTexts } from './models';

const transformPlainSettingToInstance = (setting: { name: SettingName }): Setting => {
  const SettingClass: ClassConstructor<Setting> = {
    [SettingName.PREMIUM_REWARD_TEXTS]: SettingPremiumRewardTexts,
    [SettingName.LAST_MINUTE_ESCAPES]: SettingLastMinuteEscapes
  }[setting.name];

  return plainToInstance(SettingClass, setting);
};

class SettingService {
  public search(params: PaginationRequest): Observable<PaginationResponse<Setting>> {
    const request = new PaginationRequest(omitBy(params, isUndefined));

    return apiService.get<PaginationResponse<Setting>>('/settings', instanceToPlain(request)).pipe(
      map((response) => {
        const result = plainToInstance(PaginationResponse<Setting>, response);
        const data = result.data.map((setting) => transformPlainSettingToInstance(setting));

        return { ...result, data };
      })
    );
  }
}

export const settingService = new SettingService();
