import { AxiosError } from 'axios';
import { ChangePasswordRequest } from '@shared/profile/models';
import { defineAction } from '@store/utils';

export class ChangePasswordPageActions {
  public static resetState = defineAction(
    '[Change Password Page] Reset State'
  );

  public static changePassword = defineAction<ChangePasswordRequest>(
    '[Change Password Page] Change Password'
  );

  public static changePasswordSuccess = defineAction(
    '[Change Password Page] Change Password Success'
  );

  public static changePasswordFailure = defineAction<AxiosError>(
    '[Change Password Page] Change Password Failure'
  );
}
