import { instanceToPlain, plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { configuration } from '@configuration';
import { apiService } from '@shared/api';
import { AuthCredentials, AuthResponse, RegistrationRequest, RestorePasswordRequest } from './models';

class AuthService {
  private tokenRefresh$?: Observable<string> | null;

  public authorize(credentials: AuthCredentials): Observable<AuthResponse> {
    const request = new AuthCredentials(credentials);

    return apiService
      .post('login', instanceToPlain(request))
      .pipe(map((response) => plainToInstance(AuthResponse, response)));
  }

  public requestPasswordRecovery(email: string): Observable<void> {
    return apiService.post('auth/forgot-password', { email });
  }

  public checkRestoreToken(token: string): Observable<void> {
    return apiService.post('auth/token/check', { token });
  }

  public restorePassword(token: string, password: string, repeatedPassword: string): Observable<void> {
    const request = new RestorePasswordRequest({ token, password, repeatedPassword });

    return apiService.post('auth/restore-password', instanceToPlain(request));
  }

  public checkCode(code: string): Observable<void> {
    return apiService.post('auth/temporary-code/check', { temporary_code: code });
  }

  public register(data: {
    code: string;
    email: string;
    password: string;
    repeatedPassword: string;
    remember: boolean;
  }): Observable<AuthResponse> {
    const request = new RegistrationRequest(data);

    return apiService
      .post('register', instanceToPlain(request))
      .pipe(map((response) => plainToInstance(AuthResponse, response)));
  }

  public unauthorize(): Observable<void> {
    return apiService.post('auth/logout');
  }

  public refreshToken(remember?: boolean): Observable<string | undefined> {
    if (!this.tokenRefresh$) {
      this.tokenRefresh$ = apiService
        .get(configuration.api.refreshTokenEndpoint, { remember: remember ? 1 : 0 }, { fullResponse: true })
        .pipe(
          share(),
          map((response) => {
            const authorizationHeader: string | undefined = response.headers?.authorization;

            return authorizationHeader?.split(' ')?.[1] as string;
          }),
          tap(() => {
            this.tokenRefresh$ = null;
          })
        );
    }

    return this.tokenRefresh$;
  }
}

export const authService = new AuthService();
