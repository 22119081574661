import { BaseListedEntityActions } from '@shared/base-listed-entity-store/store/actions';
import { Category } from '@shared/category';
import { CategoriesMultiselectState } from './state';

export class CategoriesMultiselectActions extends BaseListedEntityActions<CategoriesMultiselectState, Category> {
  constructor() {
    super(
      'Categories Multiselect',
      'category'
    );
  }
}

export const categoriesMultiselectActions = new CategoriesMultiselectActions();
