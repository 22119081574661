import { AxiosError } from 'axios';
import { Property } from '@shared/property/models';
import { defineAction } from '@store/utils';

export class PropertyPageActions {
  public static resetState = defineAction(
    '[Property Page] Reset State'
  );

  public static loadProperty = defineAction<{ id: number }>(
    '[Property Page] Load Property'
  );

  public static loadPropertySuccess = defineAction<Property>(
    '[Property Page] Load Property Success'
  );

  public static loadPropertiesGroupFailure = defineAction<AxiosError>(
    '[Property Page] Load Property Failure'
  );
}
