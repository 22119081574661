import { Exclude, Expose, Type } from 'class-transformer';
import { StripeProductInterval } from '../enums';

export class StripeProduct {
  @Expose()
  public id: string;

  @Expose()
  public interval: StripeProductInterval;

  @Type(() => Number)
  @Expose()
  public price: number;

  @Expose()
  public name: string;

  @Exclude()
  public get priceInGBP(): number {
    return this.price / 100;
  }

  @Exclude()
  public get isMonthly(): boolean {
    return this.interval === StripeProductInterval.MONTH;
  }

  @Exclude()
  public get isYearly(): boolean {
    return this.interval === StripeProductInterval.YEAR;
  }

  constructor(model: Partial<StripeProduct>) {
    Object.assign(this, model);
  }
}
