import { Expose } from 'class-transformer';

export class RestorePasswordRequest {
  @Expose()
  public token: string;

  @Expose()
  public password: string;

  @Expose({ name: 'confirm' })
  public repeatedPassword: string;

  constructor(model: Partial<RestorePasswordRequest> = {}) {
    Object.assign(this, model);
  }
}
