import { lazy } from 'react';

function getKey(componentName: string): string {
  return `retry-${componentName}-refreshed`;
}

export function lazyWithRetry(
  componentImport: Parameters<typeof lazy>[0],
  componentName: string
): ReturnType<typeof lazy> {
  return lazy(async () => {
    const key = getKey(componentName);
    const hasRefreshed = JSON.parse(sessionStorage.getItem(key) || 'false');

    try {
      const component = await componentImport();
      sessionStorage.setItem(key, 'false');

      return component;
    } catch (error) {
      if (hasRefreshed) {
        throw error;
      } else {
        sessionStorage.setItem(key, 'true');
        window.location.reload();

        return new Promise(() => {});
      }
    }
  });
}
