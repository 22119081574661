import { createReducer } from '@reduxjs/toolkit';
import { SignupPageActions } from './actions';
import { SignupPageState } from './state';

const initialState: SignupPageState = {
  isCheckingCode: false,
  codeError: null,
  isSubmitting: false,
  signupError: null,
  code: ''
};

export const signupPageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SignupPageActions.resetState, () => initialState)
    .addCase(SignupPageActions.checkCode, (state) => {
      state.isCheckingCode = true;
      state.codeError = null;
    })
    .addCase(SignupPageActions.checkCodeSuccess, (state, { payload: { code } }) => {
      state.isCheckingCode = false;
      state.code = code;
    })
    .addCase(SignupPageActions.checkCodeFailure, (state, { payload }) => {
      state.isCheckingCode = false;
      state.codeError = payload;
    })
    .addCase(SignupPageActions.register, (state) => {
      state.isSubmitting = true;
      state.signupError = null;
    })
    .addCase(SignupPageActions.registerSuccess, (state) => {
      state.isSubmitting = false;
    })
    .addCase(SignupPageActions.registerFailure, (state, { payload }) => {
      state.isSubmitting = false;
      state.signupError = payload;
    });
});
