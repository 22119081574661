import React, { ReactElement } from 'react';
import { IconVariant } from '@shared/icon';
import { DesktopWarningModalIcon } from '../icon/icon';
import './step.scss';

interface DesktopWarningModalStepProps {
  text: string;
  iconVariant: IconVariant;
}

export function DesktopWarningModalStep({ text, iconVariant }: DesktopWarningModalStepProps): ReactElement {
  return (
    <li className='desktopwarningmodalstep'>
      {text}
      <div className='desktopwarningmodalstep-icon'>
        <DesktopWarningModalIcon variant={iconVariant} />
      </div>
    </li>
  );
}
