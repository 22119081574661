import { AxiosError } from 'axios';
import { defineAction } from '@store/utils';

export class NewPasswordPageActions {
  public static resetState = defineAction(
    '[New Password Page] Reset State'
  );

  public static checkToken = defineAction<{ token: string }>(
    '[New Password Page] Check Token'
  );

  public static checkTokenSuccess = defineAction<{ token: string }>(
    '[New Password Page] Check Token Success'
  );

  public static checkTokenFailure = defineAction<AxiosError>(
    '[New Password Page] Check Token Failure'
  );

  public static changePassword = defineAction<{ password: string; repeatedPassword: string }>(
    '[New Password Page] Change Password'
  );

  public static changePasswordSuccess = defineAction(
    '[New Password Page] Change Password Success'
  );

  public static changePasswordFailure = defineAction<AxiosError>(
    '[New Password Page] Change Password Failure'
  );
}
