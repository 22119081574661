import { EntityService } from '@shared/base-entity/service';
import { Property, PropertyPaginationRequest } from './models';

class PropertyService extends EntityService<Property, PropertyPaginationRequest> {
  constructor() {
    super({
      endpoint: '/properties',
      entityName: 'property',
      entitySearchRequestConstructor: PropertyPaginationRequest
    });
  }
}

export const propertyService = new PropertyService();
