import { createReducer } from '@reduxjs/toolkit';
import { PasswordRecoveryPageActions } from './actions';
import { PasswordRecoveryPageState } from './state';

const initialState: PasswordRecoveryPageState = {
  isSubmitting: false,
  isSuccessDisplayed: false,
  isCountdownStarted: false
};

export const passwordRecoveryPageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(PasswordRecoveryPageActions.resetState, () => initialState)
    .addCase(PasswordRecoveryPageActions.requestPasswordRecovery, (state) => {
      state.isSubmitting = true;
    })
    .addCase(PasswordRecoveryPageActions.requestPasswordRecoverySuccess, (state) => {
      state.isSubmitting = false;
      state.isSuccessDisplayed = true;
      state.isCountdownStarted = true;
    })
    .addCase(PasswordRecoveryPageActions.resendRequestSuccess, (state) => {
      state.isSubmitting = false;
      state.isCountdownStarted = true;
    })
    .addCase(PasswordRecoveryPageActions.stopCountdown, (state) => {
      state.isCountdownStarted = false;
    });
});
