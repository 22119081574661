import { EntityService } from '@shared/base-entity/service';
import { BookedProperty, BookedPropertyPaginationRequest } from './models';

class BookedPropertyService extends EntityService<BookedProperty, BookedPropertyPaginationRequest> {
  constructor() {
    super({
      endpoint: '/booked-properties',
      entityName: 'bookedProperty',
      entitySearchRequestConstructor: BookedPropertyPaginationRequest
    });
  }
}

export const bookedPropertyService = new BookedPropertyService();
