import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { paymentService } from '@shared/payment';
import { stripeProductService } from '@shared/stripe-product';
import { AppActions } from '@store/actions';
import { Epics } from '@store/types';
import { PremiumPageActions } from './actions';

export const premiumPageEpics: Epics = {
  loadStripeProducts: (action$) => action$.pipe(
    filter(PremiumPageActions.loadStripeProducts.match),
    switchMap(() => stripeProductService.search().pipe(
      map((stripeProducts) => PremiumPageActions.loadStripeProductsSuccess(stripeProducts)),
      catchError((error) => of(PremiumPageActions.loadStripeProductsFailure(error)))
    ))
  ),

  createPaymentIntent: (action$) => action$.pipe(
    filter(PremiumPageActions.createPaymentIntent.match),
    switchMap(({ payload: { stripeProductID } }) => paymentService.createPaymentIntent(stripeProductID).pipe(
      map((subscription) => PremiumPageActions.createPaymentIntentSuccess(subscription)),
      catchError((error) => of(PremiumPageActions.createPaymentIntentFailure(error)))
    ))
  ),

  createPaymentIntentSuccess: (action$) => action$.pipe(
    filter(PremiumPageActions.createPaymentIntentSuccess.match),
    tap(({ payload: { formURL } }) => (window.location.href = formURL)),
    map(() => AppActions.noop())
  )
};
