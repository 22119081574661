import { Expose } from 'class-transformer';

export class PaymentIntentCreateRequest {
  @Expose({ name: 'stripe_product_id' })
  public stripeProductID: string;

  constructor(model: Partial<PaymentIntentCreateRequest> = {}) {
    Object.assign(this, model);
  }
}
