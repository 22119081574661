import { EntityService } from '@shared/base-entity/service';
import { PropertyType } from './models';

class PropertyTypeService extends EntityService<PropertyType> {
  constructor() {
    super({
      endpoint: '/property-types',
      entityName: 'propertyType'
    });
  }
}

export const propertyTypeService = new PropertyTypeService();
