import { Expose } from 'class-transformer';
import { SettingName } from '../enums';

export class Setting {
  @Expose()
  public name: SettingName;

  constructor(model: Partial<Setting>) {
    Object.assign(this, model);
  }
}
