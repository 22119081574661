import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { AuthActions } from '@shared/auth';
import { baseListedEntityEpics } from '@shared/base-listed-entity-store';
import { Discount, discountService } from '@shared/discount';
import { ProfileSelectors } from '@shared/profile';
import { Epics } from '@store/types';
import { rewardsPageActions } from './actions';
import { rewardsPageSelectors } from './selectors';
import { RewardsPageState } from './state';

export const rewardsPageEpics: Epics = {
  ...baseListedEntityEpics<Discount, RewardsPageState>(rewardsPageActions, rewardsPageSelectors, discountService),

  loadItems: (action$, state$) => action$.pipe(
    filter(rewardsPageActions.loadItems.match),
    switchMap((action) => {
      const params = {
        ...(rewardsPageSelectors.filters(state$.value) || {}),
        ...('payload' in action ? action.payload : {})
      };

      const browserCoords = ProfileSelectors.coords(state$.value);
      if (params.orderBy === 'distance') {
        params.browserLatitude = browserCoords.latitude;
        params.browserLongitude = browserCoords.longitude;
      }

      return discountService.search(params).pipe(
        map((response) => rewardsPageActions.loadItemsSuccess(response)),
        catchError((error) => of(rewardsPageActions.loadItemsFailure(error)))
      );
    })
  ),

  unauthorizeSuccess: (action$) => action$.pipe(
    filter(AuthActions.unauthorizeSuccess.match),
    map(() => rewardsPageActions.resetState())
  )
};
