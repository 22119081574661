import clsx from 'clsx';
import React from 'react';
import { TypeOptions } from 'react-toastify';
import { Icon, IconVariant } from '@shared/icon';
import './icon-container.scss';

interface ToastIconContainerProps {
  type: TypeOptions;
}

export function ToastIconContainer({ type }: ToastIconContainerProps): JSX.Element {
  const variant: IconVariant = type === 'success' ? 'tick' : 'attention';

  return (
    <div className={clsx('icon-container', `icon-container-${type}`)}>
      <Icon variant={variant} />
    </div>
  );
}
