import React, { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import { TabBar } from './components';

export function TabBarLayout(): ReactElement {
  return (
    <>
      <Outlet />
      <TabBar />
    </>
  );
}
