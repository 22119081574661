import { instanceToPlain, plainToInstance } from 'class-transformer';
import { isUndefined, omitBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { apiService } from '@shared/api';
import { BaseEntityPlain } from '@shared/base-entity';
import { Collection } from '@shared/collection';
import { PaginationResponse } from '@shared/pagination';
import { PropertiesGroup } from '@shared/properties-group';
import { Property } from '@shared/property';
import { SearchPaginationRequest } from './models';
import { SearchItem } from './types';

class SearchService {
  public search(params: SearchPaginationRequest): Observable<PaginationResponse<SearchItem>> {
    const request = new SearchPaginationRequest(omitBy(params, isUndefined));

    return apiService.get<PaginationResponse<BaseEntityPlain>>('/search', instanceToPlain(request)).pipe(
      map((response) => {
        const { data, ...pagination } = plainToInstance(PaginationResponse, response);

        return {
          ...pagination,
          data: data.map((item) => (item as any).properties_count ? plainToInstance(PropertiesGroup, item) : plainToInstance(Property, item))
        };
      })
    );
  }

  public searchInPropertiesGroup(
    propertiesGroupID: number,
    params: SearchPaginationRequest
  ): Observable<Array<Collection>> {
    const request = new SearchPaginationRequest(omitBy(params, isUndefined));

    return apiService
      .get<Array<BaseEntityPlain>>(`/search/${propertiesGroupID}`, instanceToPlain(request))
      .pipe(map((response) => plainToInstance(Collection, response)));
  }
}

export const searchService = new SearchService();
