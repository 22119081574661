import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '@store/types';
import { SignupPageState } from './state';

const selectFeature = (state: AppState): SignupPageState => state.signupPage;

export class SignupPageSelectors {
  public static isCheckingCode = createSelector(
    selectFeature,
    (state) => state.isCheckingCode
  );

  public static codeError = createSelector(
    selectFeature,
    (state) => state.codeError
  );

  public static isSubmitting = createSelector(
    selectFeature,
    (state) => state.isSubmitting
  );

  public static signupError = createSelector(
    selectFeature,
    (state) => state.signupError
  );

  public static code = createSelector(
    selectFeature,
    (state) => state.code
  );
}
