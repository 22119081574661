import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '@store/types';
import { NewPasswordPageState } from './state';

const selectFeature = (state: AppState): NewPasswordPageState => state.newPasswordPage;

export class NewPasswordPageSelectors {
  public static isChecking = createSelector(
    selectFeature,
    (state) => state.isChecking
  );

  public static isSubmitting = createSelector(
    selectFeature,
    (state) => state.isSubmitting
  );

  public static checkedToken = createSelector(
    selectFeature,
    (state) => state.checkedToken
  );

  public static isCompleted = createSelector(
    selectFeature,
    (state) => state.isCompleted
  );
}
