import { AxiosError } from 'axios';
import { Coords } from 'google-map-react';
import { PaginationResponse } from '@shared/pagination';
import { SearchPaginationRequest, SearchItem } from '@shared/search';
import { defineAction } from '@store/utils';

export class HomePageActions {
  public static resetState = defineAction(
    '[Home Page] Reset state'
  );

  public static loadItems = defineAction<SearchPaginationRequest>(
    '[Home Page] Load items'
  );

  public static loadItemsSuccess = defineAction<PaginationResponse<SearchItem>>(
    '[Home Page] Load items success'
  );

  public static loadItemsFailure = defineAction<AxiosError>(
    '[Home Page] Load items failure'
  );

  public static changeFilter = defineAction<SearchPaginationRequest>(
    '[Home Page] Change filter'
  );

  public static resetFilter = defineAction(
    '[Home Page] Reset filter'
  );

  public static saveSelectedPosition = defineAction<{ center: Coords; zoom: number }>(
    '[Home Page] Save Last Seen Position'
  );

  public static toggleView = defineAction(
    '[Home Page] Toggle view'
  );
}
