import { BaseListedEntityActions } from '@shared/base-listed-entity-store';
import { Discount } from '@shared/discount';
import { RewardsPageState } from './state';

export class RewardsPageActions extends BaseListedEntityActions<RewardsPageState, Discount> {
  constructor() {
    super(
      'Rewards Page',
      'discount'
    );
  }
}

export const rewardsPageActions = new RewardsPageActions();
