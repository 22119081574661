import { Expose } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';
import { BookedPropertyOrderBy } from '../types';

export class BookedPropertyPaginationRequest extends PaginationRequest<'', BookedPropertyOrderBy> {
  @Expose({ name: 'property_id' })
  public propertyID?: number;

  constructor(model: Partial<BookedPropertyPaginationRequest>) {
    super(model);

    Object.assign(this, model);
  }
}
