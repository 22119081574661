import { Exclude, Expose, Type } from 'class-transformer';
import { BaseEntity } from '@shared/base-entity';
import { Media } from '@shared/media';

export class Owner extends BaseEntity<number> {
  @Expose()
  public email: string;

  @Expose()
  public direct: string;

  @Expose({ name: 'first_name' })
  public firstName: string;

  @Expose({ name: 'last_name' })
  public lastName: string;

  @Expose()
  @Type(() => Media)
  public avatar: Media;

  @Exclude()
  public get fullName(): string {
    return [this.firstName, this.lastName].join(' ');
  }

  constructor(model: Partial<Owner>) {
    super(model);

    Object.assign(this, model);
  }
}
