import { omit } from 'lodash';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { propertiesGroupService } from '@shared/properties-group';
import { searchService } from '@shared/search';
import { Epics } from '@store/types';
import { PropertiesGroupPageActions } from './actions';

export const propertiesGroupPageEpics: Epics = {
  loadPropertiesGroup: (action$) => action$.pipe(
    filter(PropertiesGroupPageActions.loadPropertiesGroup.match),
    switchMap(({ payload: { id } }) => propertiesGroupService
      .get(id, {
        relations: ['photos', 'location'],
        withPrice: true,
        withCount: ['properties']
      })
      .pipe(
        map((response) => PropertiesGroupPageActions.loadPropertiesGroupSuccess(response)),
        catchError((error) => of(PropertiesGroupPageActions.loadPropertiesGroupFailure(error)))
      ))
  ),

  loadAllProperties: (action$) => action$.pipe(
    filter(PropertiesGroupPageActions.loadAllCollections.match),
    switchMap(({ payload: { propertiesGroupID } }) => searchService
      .searchInPropertiesGroup(propertiesGroupID, {
        all: true,
        relations: ['photos.square_1024']
      })
      .pipe(
        map((response) => PropertiesGroupPageActions.loadAllCollectionsSuccess(response)),
        catchError((error) => of(PropertiesGroupPageActions.loadAllCollectionsFailure(error)))
      ))
  ),

  loadAppropriateProperties: (action$) => action$.pipe(
    filter(PropertiesGroupPageActions.loadAppropriateCollections.match),
    switchMap(({ payload: { propertiesGroupID, filters } }) => searchService
      .searchInPropertiesGroup(propertiesGroupID, {
        ...omit(filters, ['orderBy', 'desc']),
        all: true,
        relations: ['photos.square_1024']
      })
      .pipe(
        map((response) => PropertiesGroupPageActions.loadAppropriateCollectionsSuccess(response)),
        catchError((error) => of(PropertiesGroupPageActions.loadAppropriateCollectionsFailure(error)))
      ))
  )
};
