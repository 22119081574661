import { BaseListedEntitySelectors } from '@shared/base-listed-entity-store';
import { Discount } from '@shared/discount';
import { AppState } from '@store/types';
import { RewardsPageState } from './state';

const selectFeature = (state: AppState): RewardsPageState => state.rewardsPage;

class RewardsPageSelectors extends BaseListedEntitySelectors<Discount, RewardsPageState> {
  constructor() {
    super(
      selectFeature,
      'discount'
    );
  }
}

export const rewardsPageSelectors = new RewardsPageSelectors();
