import { createReducer } from '@reduxjs/toolkit';
import { RewardViewPageActions } from './actions';
import { RewardViewPageState } from './state';

const initialState: RewardViewPageState = {
  discountID: null,
  isLoading: false
};

export const rewardViewPageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(RewardViewPageActions.resetState, () => initialState)
    .addCase(RewardViewPageActions.loadDiscount, (state) => {
      state.isLoading = true;
    })
    .addCase(RewardViewPageActions.loadDiscountSuccess, (state, { payload: { id } }) => {
      state.isLoading = false;
      state.discountID = id;
    })
    .addCase(RewardViewPageActions.loadDiscountFailure, (state) => {
      state.isLoading = false;
    });
});
