import { configureStore, Dispatch, Middleware } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './epics';
import { rootReducer } from './reducer';
import { storeRef } from './store-ref';
import { AppState } from './types';

const epicMiddleware = createEpicMiddleware({
  dependencies: {
    useDispatch: (): Dispatch => store.dispatch,
    useGetState: (): (() => AppState) => store.getState
  }
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(epicMiddleware as Middleware)
});

epicMiddleware.run(rootEpic);

storeRef.dispatch = store.dispatch;
storeRef.getState = store.getState;
