import { I18n } from 'i18n-js';
import { configuration } from '@configuration';

const i18n = new I18n({
  en: require('@assets/i18n/en.json')
});
i18n.defaultLocale = configuration.language.default;
i18n.locale = configuration.language.default;

export default i18n;
