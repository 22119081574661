import { AxiosError } from 'axios';
import { User } from '@shared/user';
import { defineAction } from '@store/utils';

export class ProfileFormActions {
  public static resetState = defineAction(
    '[Profile Form] Reset State'
  );

  public static update = defineAction<{ user: User; onSuccess: (
  ) => void }>('[Profile Form] Update'
    );

  public static updateSuccess = defineAction(
    '[Profile Form] Update Success'
  );

  public static updateFailure = defineAction<AxiosError>(
    '[Profile Form] Update Failure'
  );
}
