import clsx from 'clsx';
import React, { ReactElement, ReactNode, useEffect } from 'react';
import ReactModal from 'react-modal';
import { ModalID } from './enums';
import './modal.scss';

interface ModalProps {
  id?: ModalID;
  isOpen: boolean;
  isFullScreen?: boolean;
  onRequestClose?: () => void;
  onAfterClose?: () => void;
  children: ReactNode;
  overlayClassName?: string;
  className?: string;
}

export function Modal({
  id,
  isOpen,
  isFullScreen,
  onRequestClose,
  onAfterClose,
  children,
  overlayClassName,
  className
}: ModalProps): ReactElement {
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
  }, [isOpen]);

  return (
    <ReactModal
      id={id}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onAfterClose={onAfterClose}
      overlayClassName={clsx('modal-overlay', overlayClassName)}
      className={clsx('modal', className, isFullScreen && 'modal-fullscreen')}>
      {children}
    </ReactModal>
  );
}
