import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { connectModal, InjectedProps } from 'redux-modal';
import logoSmall from '@assets/images/logo-small.svg';
import logo from '@assets/images/logo-white.svg';
import { configuration } from '@configuration';
import { useTranslation } from '@shared/i18n';
import { AppLink } from '@shared/link';
import { Modal, ModalID } from '@shared/modal';
import { useResponsiveLayout } from '@shared/use-responsive-layout';
import { DesktopWarningModalIcon, DesktopWarningModalStep } from './components';
import './desktop-warning-modal.scss';

function DesktopWarningModalComponent({ show: isOpen }: InjectedProps): ReactElement {
  const translate = useTranslation('SHARED.DESKTOP_WARNING_MODAL');
  const loginURL = process.env.REACT_APP_INTRO_URL;
  const domain = process.env.REACT_APP_DOMAIN;
  const { isTablet } = useResponsiveLayout();

  return (
    <Modal
      id={ModalID.DESKTOP_WARNING}
      isOpen={isOpen}
      isFullScreen
      className='desktop-warning-modal'>
      <div className='desktop-warning-modal-header'>
        <div className='content'>
          <img src={logo} alt={translate('TEXT_LOGO')} />
        </div>
      </div>
      <div className={clsx('desktop-warning-modal-main', 'content')}>
        <div className='desktop-warning-modal-card'>
          <div className='desktop-warning-modal-content'>
            <h2 className='desktop-warning-modal-title'>{translate('TEXT_TITLE')}</h2>
            <p className='desktop-warning-modal-description'>{translate('TEXT_DESCRIPTION', { domain })}</p>
            <ol>
              <DesktopWarningModalStep text={translate('TEXT_STEP_1')} iconVariant='camera' />
              <DesktopWarningModalStep text={translate('TEXT_STEP_2')} iconVariant='scan' />
              <DesktopWarningModalStep text={translate('TEXT_STEP_3')} iconVariant='globe' />
            </ol>
            <div className='desktop-warning-modal-email'>
              <div>
                <p>{translate('TEXT_RECEIVED_EMAIL')}</p>
                <p>
                  <strong>{translate('TEXT_VERIFY_EMAIL')}</strong>
                </p>
              </div>
              <DesktopWarningModalIcon variant='smartphone' />
            </div>
          </div>
          <div className='desktop-warning-modal-qr'>
            <QRCode
              value={loginURL}
              size={isTablet ? 207 : 276}
              logoImage={logoSmall}
              logoWidth={isTablet ? 51 : 70}
              qrStyle='fluid'
              eyeRadius={16}
              removeQrCodeBehindLogo
            />
          </div>
        </div>
      </div>
      <div className='desktop-warning-modal-footer'>
        <p>
          {translate('TEXT_AGREEMENT')}{' '}
          <AppLink href={configuration.links.termsOfService} target='_blank'>
            {translate('LINK_TERMS_OF_SERVICE')}
          </AppLink>{' '}
          {translate('TEXT_AND')}{' '}
          <AppLink href={configuration.links.privacyPolicy} target='_blank'>
            {translate('LINK_PRIVACY_POLICY')}
          </AppLink>
        </p>
        <p className='desktop-warning-modal-copyright'>
          © 2022-{new Date().getFullYear()} {translate('TEXT_APP_NAME')}
        </p>
      </div>
    </Modal>
  );
}

export const DesktopWarningModal = connectModal({
  name: ModalID.DESKTOP_WARNING,
  destroyOnHide: false
})(DesktopWarningModalComponent);
