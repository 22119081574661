import { AxiosError } from 'axios';
import { RegistrationRequest } from '@shared/auth';
import { defineAction } from '@store/utils';

export class SignupPageActions {
  public static resetState = defineAction(
    '[Signup Page] Reset State'
  );

  public static checkCode = defineAction<{ code: string }>(
    '[Signup Page] Check Code'
  );

  public static checkCodeSuccess = defineAction<{ code: string }>(
    '[Signup Page] Check Code Success'
  );

  public static checkCodeFailure = defineAction<AxiosError>(
    '[Signup Page] Check Code Failure'
  );

  public static register = defineAction<Omit<RegistrationRequest, 'code' | 'remember'>>(
    '[Signup Page] Register'
  );

  public static registerSuccess = defineAction(
    '[Signup Page] Register Success'
  );

  public static registerFailure = defineAction<AxiosError>(
    '[Signup Page] Register Failure'
  );
}
