import { createReducer } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import { ChangePasswordPageActions } from './actions';
import { ChangePasswordPageState } from './state';

const initialState: ChangePasswordPageState = {
  isSubmitting: false,
  isCompleted: false,
  hasError: false
};

export const changePasswordPageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(ChangePasswordPageActions.resetState, () => initialState)
    .addCase(ChangePasswordPageActions.changePassword, (state) => {
      state.isSubmitting = true;
      state.hasError = false;
      state.isCompleted = false;
    })
    .addCase(ChangePasswordPageActions.changePasswordSuccess, (state) => {
      state.isSubmitting = false;
      state.isCompleted = true;
    })
    .addCase(ChangePasswordPageActions.changePasswordFailure, (state, { payload }) => {
      state.isSubmitting = false;
      state.hasError = payload.response?.status === StatusCodes.UNPROCESSABLE_ENTITY;
    });
});
