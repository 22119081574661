import { BaseListedEntityActions } from '@shared/base-listed-entity-store/store/actions';
import { PropertyType } from '@shared/property-type';
import { PropertyTypesMultiselectState } from './state';

export class PropertyTypesMultiselectActions extends BaseListedEntityActions<
  PropertyTypesMultiselectState,
  PropertyType
> {
  constructor() {
    super(
      'Property Types Multiselect',
      'propertyType'
    );
  }
}

export const propertyTypesMultiselectActions = new PropertyTypesMultiselectActions();
