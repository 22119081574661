import React from 'react';
import ReactDOM from 'react-dom';
import './styles/globals.scss';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { App } from '@app/app';
import { store } from '@store';

const router = createBrowserRouter([{ path: '*', element: <App /> }]);

ReactDOM.render(
  <Provider store={store}>
    <SkeletonTheme
      borderRadius={16}
      baseColor='#F5F8FA'
      highlightColor='#F9FCFF'>
      <RouterProvider router={router} />
    </SkeletonTheme>
  </Provider>,
  document.getElementById('root')
);
