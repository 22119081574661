import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { apiService } from '@shared/api';
import { StripeProduct } from './models';

class StripeProductService {
  public search(): Observable<Array<StripeProduct>> {
    return apiService
      .get<Array<StripeProduct>>('/payment/products')
      .pipe(map((response) => plainToInstance(StripeProduct, response)));
  }
}

export const stripeProductService = new StripeProductService();
