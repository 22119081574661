import { DateTime } from 'luxon';
import { mergeMap, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, tap } from 'rxjs/operators';
import { apiService, tokenRefreshInterceptor } from '@shared/api';
import { cookieService, defaultCookieOptions } from '@shared/cookie';
import { ProfileActions } from '@shared/profile/store/actions';
import { AppActions } from '@store/actions';
import { Epics } from '@store/types';
import { authService } from '../service';
import { AuthActions } from './actions';
import { AuthSelectors } from './selectors';

export const authEpics: Epics = {
  onInit: (action$, _, { useDispatch, useGetState }) => action$.pipe(
    filter(AppActions.init.match),
    tap(() => {
      const getState = useGetState();
      const dispatch = useDispatch();

      apiService.useInterceptors({
        response: [
          [
            undefined,
            tokenRefreshInterceptor({
              getIsAuthenticated: () => AuthSelectors.isAuthenticated(getState()),
              refreshToken: authService.refreshToken,
              onError: () => dispatch(AuthActions.unauthorizeSuccess())
            })
          ]
        ]
      });
    }),
    map(() => {
      const isAuthenticated = cookieService.get('isAuthenticated') === 'true';

      return AuthActions.setIsAuthenticated({ value: isAuthenticated });
    })
  ),

  setIsAuthenticated: (action$) => action$.pipe(
    filter(AuthActions.setIsAuthenticated.match),
    filter(({ payload: { value } }) => !!value),
    mergeMap(() => [ProfileActions.refreshProfile(), ProfileActions.getLocation(), ProfileActions.loadSettings()])
  ),

  authorize: (action$) => action$.pipe(
    filter(AuthActions.authorize.match),
    exhaustMap((action) => authService.authorize({ ...action.payload, remember: true }).pipe(
      map((response) => AuthActions.authorizeSuccess({ response, navigateTo: '/home' })),
      catchError((error) => of(AuthActions.authorizeFailure(error)))
    ))
  ),

  authorizeSuccess: (action$) => action$.pipe(
    filter(AuthActions.authorizeSuccess.match),
    tap(({ payload: { response } }) => {
      cookieService.set(
        { isAuthenticated: 'true' },
        {
          ...defaultCookieOptions,
          expires: DateTime.now()
            .plus({ minute: response.ttl + response.refreshTTL })
            .toJSDate()
        }
      );
    }),
    map(() => AuthActions.setIsAuthenticated({ value: true }))
  ),

  unauthorize: (action$) => action$.pipe(
    filter(AuthActions.unauthorize.match),
    exhaustMap(() => authService.unauthorize().pipe(map(() => AuthActions.unauthorizeSuccess())))
  ),

  unauthorizeSuccess: (action$) => action$.pipe(
    filter(AuthActions.unauthorizeSuccess.match),
    tap(() => cookieService.destroy(['isAuthenticated'])),
    mergeMap(() => [AuthActions.setIsAuthenticated({ value: false }), ProfileActions.clearProfile()])
  )
};
