import { AxiosError } from 'axios';
import { PaymentIntent } from '@shared/payment';
import { StripeProduct } from '@shared/stripe-product';
import { defineAction } from '@store/utils';

export class PremiumPageActions {
  public static resetState = defineAction(
    '[Premium Page] Reset State'
  );

  public static loadStripeProducts = defineAction(
    '[Premium Page] Load Stripe Products'
  );

  public static loadStripeProductsSuccess = defineAction<Array<StripeProduct>>(
    '[Premium Page] Load Stripe Products Success'
  );

  public static loadStripeProductsFailure = defineAction<AxiosError>(
    '[Premium Page] Load Stripe Products Failure'
  );

  public static createPaymentIntent = defineAction<{ stripeProductID: string }>(
    '[Premium Page] Create Payment Intent'
  );

  public static createPaymentIntentSuccess = defineAction<PaymentIntent>(
    '[Premium Page] Create Payment Intent Success'
  );

  public static createPaymentIntentFailure = defineAction<AxiosError>(
    '[Premium Page] Create Payment Intent Failure'
  );
}
