import React, { ReactElement } from 'react';
import { Icon, IconVariant } from '@shared/icon';
import './icon.scss';

interface DesktopWarningModalIconProps {
  variant: IconVariant;
}

export function DesktopWarningModalIcon({ variant }: DesktopWarningModalIconProps): ReactElement {
  return (
    <div className='desktopwarningmodalicon'>
      <Icon variant={variant} className='desktopwarningmodalicon-image' />
    </div>
  );
}
