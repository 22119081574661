import { createReducer } from '@reduxjs/toolkit';
import { EditProfilePageActions } from './actions';
import { EditProfilePageState } from './state';

const initialState: EditProfilePageState = {
  isDeleting: false
};

export const editProfilePageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(EditProfilePageActions.deleteAccount, (state) => {
      state.isDeleting = true;
    })
    .addCase(EditProfilePageActions.deleteAccountSuccess, (state) => {
      state.isDeleting = false;
    })
    .addCase(EditProfilePageActions.deleteAccountFailure, (state) => {
      state.isDeleting = false;
    });
});
