import { createReducer } from '@reduxjs/toolkit';
import { searchModalActions } from '@app/main/shared/search-modal/store/actions';
import { configuration } from '@configuration';
import { Pagination } from '@shared/pagination';
import { SearchPaginationRequest } from '@shared/search/models';
import { HomeView } from '../enums';
import { HomePageActions } from './actions';
import { HomePageState } from './state';

const initialState: HomePageState = {
  items: [],
  pagination: new Pagination(),
  center: null,
  zoom: null,
  filters: new SearchPaginationRequest({
    perPage: 100,
    orderBy: 'featured'
  }),
  placeCoords: null,
  view: HomeView.LIST,
  isLoading: true
};

export const homePageReducer = createReducer(initialState, (builder) => builder
  .addCase(HomePageActions.resetState, () => initialState)
  .addCase(HomePageActions.loadItems, (state) => {
    state.isLoading = true;
  })
  .addCase(HomePageActions.loadItemsSuccess, (state, { payload: { data, ...pagination } }) => {
    state.isLoading = false;
    state.items = state.pagination.currentPage < pagination.currentPage ? state.items.concat(data) : data;
    state.pagination = pagination;
  })
  .addCase(HomePageActions.loadItemsFailure, (state) => {
    state.isLoading = false;
  })
  .addCase(HomePageActions.changeFilter, (state, { payload }) => {
    state.filters = { ...state.filters, ...payload };
    state.isLoading = true;
  })
  .addCase(HomePageActions.resetFilter, (state) => {
    state.filters = initialState.filters;
    state.isLoading = true;
  })
  .addCase(searchModalActions.getPlaceDetailsSuccess, (state, { payload: { coords, types } }) => {
    state.placeCoords = coords;
    state.center = coords;
    state.zoom = types?.includes('administrative_area_level_2')
      ? configuration.map.administrativeAreaLevelTwoZoom
      : configuration.map.defaultZoom;
    state.view = HomeView.MAP;
  })
  .addCase(HomePageActions.saveSelectedPosition, (state, { payload: { center, zoom } }) => {
    state.center = center;
    state.zoom = zoom;
  })
  .addCase(HomePageActions.toggleView, (state) => {
    state.view = state.view === HomeView.MAP ? HomeView.LIST : HomeView.MAP;
    state.isLoading = true;
  }));
