import { Expose } from 'class-transformer';
import { TransformBoolean } from '@shared/class-transformer';

export class AuthCredentials {
  @Expose()
  public email: string;

  @Expose()
  public password: string;

  @Expose()
  @TransformBoolean()
  public remember: boolean;

  constructor(credentials: Partial<AuthCredentials>) {
    Object.assign(this, credentials);
  }
}
