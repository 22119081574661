import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, tap } from 'rxjs/operators';
import i18n from '@shared/i18n/i18n';
import { profileService } from '@shared/profile';
import { toastService } from '@shared/toast';
import { AppActions } from '@store/actions';
import { Epics } from '@store/types';
import { ChangePasswordPageActions } from './actions';

export const changePasswordPageEpics: Epics = {
  changePassword: (action$) => action$.pipe(
    filter(ChangePasswordPageActions.changePassword.match),
    exhaustMap(({ payload }) => profileService.changePassword(payload).pipe(
      map(() => ChangePasswordPageActions.changePasswordSuccess()),
      catchError((error) => of(ChangePasswordPageActions.changePasswordFailure(error)))
    ))
  ),

  changePasswordSuccess: (action$) => action$.pipe(
    filter(ChangePasswordPageActions.changePasswordSuccess.match),
    tap(() => toastService.show(i18n.t('MAIN.CHANGE_PASSWORD.TEXT_PASSWORD_SAVED'), 'success')),
    map(() => AppActions.noop())
  )
};
